import React from "react";
import Common from "../../layouts/Common";
// import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { FaLockOpen } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaWaveSquare } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import indu1 from "../../assets/images/indu1.svg";
import indu2 from "../../assets/images/indu2.svg";
import indu3 from "../../assets/images/indu3.svg";
import indu4 from "../../assets/images/indu4.svg";
import indu5 from "../../assets/images/indu5.svg";
import indu6 from "../../assets/images/indu6.svg";
import indcom from "../../assets/images/indcom.svg";
import about from "../../assets/images/about.svg";
import itconimg from "../../assets/images/it-consulting-service-pic.png";

const HireDedicated = () => {
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>
                    Hire Developers in Bangladesh
                    <br />
                    in Bangladesh{" "}
                  </strong>
                </h1>
                <p className="text-white p20 mb-4">
                  Swift IT Solutions is an ideal choice for you if you are
                  looking to hire developers in Bangladesh and take services of
                  highly-skilled, experienced and dedicated developers for your
                  project needs. Our dedicated developers in Bangladesh are
                  currently being hired by many clients from across the globe as
                  their resources who are constantly in touch with them as if
                  they are their own team members. Swift IT helps you hire
                  programmers in Bangladesh as your extended team and scale your
                  development capacity.Once into hire agreement the client has
                  direct access to the hired developer and can give instructions
                  directly to the developer and get the project going as per his
                  requirement.
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaLockOpen />
                    </span>
                    <label> Security & IP Protection </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaGlobe />
                    </span>
                    <label> 5 Years Experience </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaWaveSquare />
                    </span>
                    <label> Flexible Engagement Models </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileAlt />
                    </span>
                    <label> Strict NDA Terms </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileInvoice />
                    </span>
                    <label> No Hidden Costs </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaClock />
                    </span>
                    <label> On-Time Project Delivery </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <div className="get-touch-form">
                <Form>
                  <div className="mb-3 me-0 sch-block">
                    <h3 className="h3-schedule mb-1">
                      {" "}
                      <strong>Lets Discuss Your Project</strong>{" "}
                    </h3>
                    <p className="mb-5">
                      Guaranteed Response within One Business Day!
                    </p>
                  </div>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaUserAlt />
                    </span>
                    <Form.Control placeholder="Name" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re" controlId="">
                    {" "}
                    <span>
                      <FaCommentAlt />
                    </span>
                    <Form.Control type="email" placeholder="Email" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaMobileAlt />
                    </span>
                    <Form.Control type="text" placeholder="Contact Number" />
                  </Form.Group>
                  <Form.Group
                    className="mb-4 pos-re"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {" "}
                    <span className="comspan">
                      <FaCommentDots />
                    </span>
                    <Form.Control
                      as="textarea"
                      className="textform"
                      placeholder="Comment"
                      rows={2}
                    />
                  </Form.Group>
                  <Col md={12} className="text-center mt-4">
                    <Button variant="primary btn-custom mt-3 btn-big">
                      Submit
                      <FaArrowRight className="ms-5" />
                    </Button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature">
        <Container>
          <div className="company-slide mission-vission border-0 p-5 box-shadow-none">
            <Row>
              <Col md={12} className="ps-0">
                <h2 className="p-0">
                  Why hire developers in Bangladesh from Swift IT?
                </h2>
                <p className="mb-0">
                  We have proven track record of highly satisfied clients who
                  had taken services of our hired dedicated developers for .net,
                  mobile app development ,php and many other assignments.
                  Clients can hire our developers on flexible payment options
                  like contract, full time, part time or hourly, weekly monthly
                  basis. All our hiring plans and services are available at very
                  cost effective offers and you have the flexibility to choose
                  our developers based on their skills to suite your project or
                  budget requirements. You can easily connect with us to hire
                  dedicated developers in Bangladesh at the best price.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>
                The Hiring Process Of Software Developers & Programmers At Swift
                IT
              </h2>

              <ul className="list-inline list-group ul-list-about mt-4 hire-ul">
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Enquiry made by a Prospective Client through our Website or
                    Skype or Chat Support.
                  </p>
                </li>

                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Swift IT Bangladesh provides the resumes of the developers
                    or programmers who are proficient in the technology that the
                    client requires.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Client shortlist the developers from the resume’s that
                    provided by Swift IT.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Clients will have your own dedicated developer(s) here in
                    our office for your projects. They will work as your
                    Offshore developers similar to your in-house team.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Clients will have complete control over your developers
                    through Chat/ Emails and most importantly by viewing their
                    progress using our PMS. Client can assign the work for their
                    developers directly.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Clients will be provided a guest Account on our PMS where
                    you can track the progress and productivity of your
                    developers.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Ramping up and downof the resources will be done within 20
                    days’ notice time.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Developers will be backed up with Part time team leads and
                    Part time project Managers. Clients can hire Full time Team
                    leads if they want when the team size increases. This can
                    bring in better results in managing the team.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Normal working days will be 5 days a week and working hours
                    will be 9 hours per day.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>The payment will be through Wire Transfer.</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Invoices will be sent on a Monthly basis.</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    There will be 12 Holidays for the company and 12 leaves
                    allowed for the developers in a year.
                  </p>
                </li>
              </ul>
            </Col>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="abouimg mt-5" src={about} alt="about" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6} className="text-start mt-4 mb-4">
              <h2>
                Benefits Of Hiring Developers & Programmers In Bangladesh From
                Swift IT{" "}
              </h2>
              <ul className="list-inline list-group ul-list-about mt-4 hire-ul">
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Expert manpower at lower cost </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>English speaking professionals easy for communication </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Flexible payment options </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Save on Infrastructure cost </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Save on hiring and management cost </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Save on overhead costs </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Get daily , weekly and monthly reports</p>
                </li>
              </ul>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={itconimg} alt="serviceall" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature bg-deep-gray mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={indcom} alt="serviceall" />
            </Col>
            <Col md={6} className="text-start mt-4 mb-4">
              <h2>
                Hire Bangladesh Developers & Programmers From Swift IT Solutions
              </h2>
              <p>
                As the leading software outsourcing company in Bangladesh, Swift
                IT Solutions are among the best in providing dedicated
                professionals on hourly, weekly, monthly or full time /
                part-time basis to our clients for project development in
                different software technologies. When you hire developers in
                Bangladesh from Swift IT, you get the maximum flexibility and
                assurance in terms of the service. We have an experience of
                providing this service for last 10 years to our esteem clients
                taking utmost care of the quality processes and industry best
                practices. You get the best when you hire programmers in
                Bangladesh from us.
              </p>
              <ul className="list-inline list-group ul-list-about mt-4 hire-ul">
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Free consultation for hire services </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Large pool of dedicated resources available </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Professionals with specialization on technology </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    25+ professionals currently dedicated to clients on Hire{" "}
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Save on your administration cost </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Mediation and monitoring by our Project manager </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>100% transparency and professional approach</p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
              <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu1} alt="indu1" />{" "}
                </div>
                <h5 className="text-center mt-5">Ecommerce</h5>
                <p>
                  {" "}
                  The e-commerce solutions offered at Swift IT are on-the-go and
                  assured to bring benefits with increased ROI and overall
                  productivity. We provide custom e-commerce development
                  services and develop web apps for any kind of business across
                  various industries and enterprises ranging from B2B and B2C
                  trading networks to small, middle-sized, and large businesses.
                  These custom solutions meet the user’s requirements by
                  applying specific areas of specialization.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu2} alt="indu2" />{" "}
                </div>
                <h5 className="text-center mt-5">Healthcare</h5>
                <p>
                  {" "}
                  At Swift IT, we help you develop healthcare software
                  applications that automate processes such as patient
                  management, lab management, test result analysis, and data
                  collection. For the medical technology companies, from
                  consulting to maintenance and support we always lent our
                  supporting hands to tackle your technological dilemmas.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu3} alt="indu3" />{" "}
                </div>
                <h5 className="text-center mt-5">Logistics</h5>
                <p>
                  The experts at Swift IT help you build innovative and
                  impressive tech-enabled logistics software applications to
                  streamline the supply chain and provide flawless customer
                  support. The warehouse management software also makes sure to
                  enhance warehouse efficiency. The expert logistics and
                  transportation management software offered by Swift IT thereby
                  help you do your tasks more effectively.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu4} alt="indu4" />{" "}
                </div>
                <h5 className="text-center mt-5">Education</h5>
                <p>
                  {" "}
                  In the education field, Swift IT is engaged in integrated
                  learning system software development, virtual learning
                  management system, LMS software development, and even
                  personalized e-learning software systems. Interactive,
                  convenient, and cost-effective solutions are what we offer at
                  Swift IT.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu5} alt="indu5" />{" "}
                </div>
                <h5 className="text-center mt-5">Banking</h5>
                <p>
                  {" "}
                  From financial software development, custom banking software
                  development, digital banking software services, and custom
                  Fintech development, we at Swift IT extend our software
                  development experience in the industry of banking and finance
                  as well. With custom features, and UI/UX designs we ensure the
                  highest standards and security to the software developed.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu6} alt="indu6" />{" "}
                </div>
                <h5 className="text-center mt-5">Manufacturing</h5>
                <p>
                  {" "}
                  Implement manufacturing software for ensuring quality
                  management and maintenance software including the application
                  of intelligent products that help in accelerating the existing
                  processes and bringing down operational costs.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default HireDedicated;
