import React from "react";

import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { FaLockOpen } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaWaveSquare } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaSlideshare } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";

import { FaStarOfLife } from "react-icons/fa";
import { FaScribd } from "react-icons/fa";
import { FaFileCode } from "react-icons/fa";
import { FaSearchengin } from "react-icons/fa";
import { FaSolarPanel } from "react-icons/fa";
import { FaScrewdriver } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import worka from "../../assets/images/worka.png";
import workb from "../../assets/images/workb.png";
import workc from "../../assets/images/workc.png";
import workd from "../../assets/images/workd.png";
import worke from "../../assets/images/worke.png";
import workf from "../../assets/images/workf.png";
import indu1 from "../../assets/images/indu1.svg";
import indu2 from "../../assets/images/indu2.svg";
import indu3 from "../../assets/images/indu3.svg";
import indu4 from "../../assets/images/indu4.svg";
import indu5 from "../../assets/images/indu5.svg";
import indu6 from "../../assets/images/indu6.svg";
import uiservice from "../../assets/images/ui-service-pic.png";
import uitrustedpic from "../../assets/images/ui-trusted-pic.png";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const UIUXDesign = () => {
  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>Best UI UX Design Company in Bangladesh </strong>
                </h1>
                <p className="text-white p20 mb-4">
                  Are you looking for an experienced UI UX design company in
                  Bangladesh that can design a captivating user interface and
                  improve the user experience of the site? We are here to
                  provide you with the best UI design that will give an
                  intuitive navigational experience. We can help you optimize
                  your business process by delivering robust and unmatchable
                  UI/UX design. We design websites that consider customer
                  interactions and impressions and we make sure that the website
                  performs to its maximum potential. No matter what kind of UX
                  or UI design services you require, we will assist you to
                  thrive in today's highly digital world.
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaLockOpen />
                    </span>
                    <label> Security & IP Protection </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaGlobe />
                    </span>
                    <label> 5 Years Experience </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaWaveSquare />
                    </span>
                    <label> Flexible Engagement Models </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileAlt />
                    </span>
                    <label> Strict NDA Terms </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileInvoice />
                    </span>
                    <label> No Hidden Costs </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaClock />
                    </span>
                    <label> On-Time Project Delivery </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <div className="get-touch-form">
                <Form>
                  <div className="mb-3 me-0 sch-block">
                    <h3 className="h3-schedule mb-1">
                      {" "}
                      <strong>Our Software QA & Testing Services</strong>{" "}
                    </h3>
                    <p className="mb-5">
                      Being an expert company in Bangladesh for both software
                      testing and quality assurance, we offer cost-effective and
                      high-quality QA solutions in compliance with industry
                      regulations like GAMP, PCI DSS, HIPAA, and others. Our QA
                      engineers will offer reliable testing services to provide
                      you with secure, robust, and feature-rich applications
                      that suit your business needs.
                    </p>
                  </div>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaUserAlt />
                    </span>
                    <Form.Control placeholder="Name" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re" controlId="">
                    {" "}
                    <span>
                      <FaCommentAlt />
                    </span>
                    <Form.Control type="email" placeholder="Email" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaMobileAlt />
                    </span>
                    <Form.Control type="text" placeholder="Contact Number" />
                  </Form.Group>
                  <Form.Group
                    className="mb-4 pos-re"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {" "}
                    <span className="comspan">
                      <FaCommentDots />
                    </span>
                    <Form.Control
                      as="textarea"
                      className="textform"
                      placeholder="Comment"
                      rows={2}
                    />
                  </Form.Group>
                  <Col md={12} className="text-center mt-4">
                    <Button variant="primary btn-custom mt-3 btn-big">
                      Submit
                      <FaArrowRight className="ms-5" />
                    </Button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our UI/UX Design Services</h2>
              <p>
                As a leading UI UX company in Bangladesh, we can design a great
                UI UX layout in such a way as to reduce your bounce rate and
                increase the time that users spend on your website. We are
                highly talented in giving you the best UI/UX service that will
                matter the most in your business. Swift IT can help you enhance
                your brand value by providing user-centric and feature-rich
                applications.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaStarOfLife />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Functional Testing</h5>
                    <p className="mb-0">
                      We are skilled in validating software to check whether it
                      meets different functional specifications and requirements
                      to deliver high-quality software solutions. We efficiently
                      test each functionality by providing valid inputs and
                      inspecting output to examine whether it is relevant to the
                      required specification. By leveraging tools like Postman,
                      TestComplete, Chrome Development Tools, Avo Assure, Jira,
                      Trello, Redmine, etc., we perform testing for the seamless
                      functioning of applications. We will deliver error-free
                      and feature-rich applications that meet your business
                      demands.
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Load & Performance Testing</h5>
                    <p className="mb-0">
                      We are experts in testing the behaviour of software
                      solutions by evaluating their speed and reliability under
                      varying loads. This will eliminate bottlenecks in the
                      development phase. We are capable of determining the
                      performance of applications by checking their ability to
                      withstand the load, when it is accessed by numerous users
                      at a time. By leveraging tools like LoadRunner, NeoLoad,
                      JMeter, Chrome Development Tools, etc., we are able to
                      rectify performance risks and deliver reliable and robust
                      software solutions as per your requirements.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSlideshare />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={uiservice} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaScribd />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Wireframes And Prototypes</h5>
                    <p className="mb-0 pb-3">
                      We can create wireframes and prototypes that provide
                      valuable insights to build high-quality applications that
                      cater to your business needs. In wireframes, we generate
                      interface elements present on key pages that provide a
                      sketch of the structure and a layout of the pages. In
                      prototypes, we produce basic structures that include
                      functional aspects as well as display aspects of the
                      digital product. By leveraging trending tools, we generate
                      wireframes and prototypes and use them efficiently to
                      design and develop high-quality software applications. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">User Interface Design</h5>
                    <p className="mb-0">
                      Our UI UX design services in Bangladesh comprise all of
                      the fundamental and important aspects of a user-friendly
                      UI design such as app usability and visual design. We
                      provide enticing user interfaces for web and mobile apps
                      that make a long-lasting impression on the audience. We
                      consider UI studio as a versatile choice to generate UI
                      that is easy to navigate and operate. Utilise our expert
                      designers to build visually appealing and unique user
                      interface designs for your applications.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Interaction Design</h5>
                    <p className="mb-0 pb-4">
                      Our UI designers concentrate on how users interact with
                      products and use the requirements skillfully to develop
                      desired user experiences. We can deliver the most suitable
                      interactive designs for your digital products that provide
                      an enhanced interactive experience. We provide UI UX
                      design services in Bangladesh with the goal of ensuring
                      that your website reaches the optimal balance between
                      people and machines. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Redesigning</h5>
                    <p className="mb-0">
                      We can provide reliable services to modernize your
                      outdated design with poor organisation and consistency and
                      improve usability. Our developers use state-of-the-art
                      technology to redesign your application with an enticing
                      UI/UX design. We understand your requirements thoroughly
                      and redesign your product so that it can meet your
                      business demands. We are efficient in delivering a
                      successful digital product within your budget and on
                      schedule.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSolarPanel />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-3">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaScrewdriver />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">User Testing</h5>
                    <p className="mb-0 pb-4">
                      We highly concentrate on the usability of the web and
                      mobile apps and provide flawless, high-performance,
                      highly-functional digital solutions. We are one of the
                      most opted UI UX design agencies in Bangladesh that
                      leverage the latest testing methodologies to deliver
                      bug-free software products that cater to your needs. Our
                      experienced UI/UX designers will evaluate your app by
                      implementing usability testing on potential users to
                      ensure quality. We will thoroughly analyse whether the
                      applications meet your specific requirements and receive
                      feedback from you for further improvement. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Visual Design</h5>
                    <p className="mb-0 pb-5 mb-3">
                      Our experts follow the best practices for visual designing
                      that comprises Gestalt properties, hierarchy, space,
                      contrast, colour balance, scale, dominance, and
                      similarity. We provide outstanding visual design services
                      for web and mobile apps by integrating the best
                      combination of colours, fonts, and images. We employ
                      graphics, photography, space, layouts, typography, and
                      colour in our visual designs to shape the browsing
                      experience of the website.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSlideshare />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img className="width-full mt-5" src={uitrustedpic} alt="indcom" />
          </Col>
          <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className="mt-5 pt-4">Our Expertise In UI/UX Design</h2>
            <p>
              We have solid expertise in creating wireframes, prototypes,
              mockups, and user flows that are required for the development of
              software applications. We leverage tools such as MS Visio,
              InVision, Sketch, Adobe XD and more for the design purpose. We
              have extensive knowledge in visual design best practices like
              general design theory, colour theory, layout, and typography that
              help a lot in designing user-centric websites. Renowned as one of
              the best UI UX companies in Bangladesh, we are efficient in
              designing visual elements of applications with the help of design
              software like Photoshop, Figma, Illustrator, and Sketch. We have
              hands-on experience in User Research and Usability Testing to
              improve the quality of your product.
            </p>
          </Col>
        </Row>
      </Container>

      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel
              className="owl-theme slider-custom"
              {...options2}
              loop
              margin={20}
              nav
            >
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worka}
                    alt="worka"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workb}
                    alt="workb"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workc}
                    alt="workc"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workd}
                    alt="workd"
                  />{" "}
                </div>
              </div>

              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worke}
                    alt="worke"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workf}
                    alt="workf"
                  />{" "}
                </div>
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
              <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu1} alt="indu1" />{" "}
                </div>
                <h5 className="text-center mt-5">Ecommerce</h5>
                <p>
                  {" "}
                  The e-commerce solutions offered at Swift IT are on-the-go and
                  assured to bring benefits with increased ROI and overall
                  productivity. We provide custom e-commerce development
                  services and develop web apps for any kind of business across
                  various industries and enterprises ranging from B2B and B2C
                  trading networks to small, middle-sized, and large businesses.
                  These custom solutions meet the user’s requirements by
                  applying specific areas of specialization.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu2} alt="indu2" />{" "}
                </div>
                <h5 className="text-center mt-5">Healthcare</h5>
                <p>
                  {" "}
                  At Swift IT, we help you develop healthcare software
                  applications that automate processes such as patient
                  management, lab management, test result analysis, and data
                  collection. For the medical technology companies, from
                  consulting to maintenance and support we always lent our
                  supporting hands to tackle your technological dilemmas.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu3} alt="indu3" />{" "}
                </div>
                <h5 className="text-center mt-5">Logistics</h5>
                <p>
                  The experts at Swift IT help you build innovative and
                  impressive tech-enabled logistics software applications to
                  streamline the supply chain and provide flawless customer
                  support. The warehouse management software also makes sure to
                  enhance warehouse efficiency. The expert logistics and
                  transportation management software offered by Swift IT thereby
                  help you do your tasks more effectively.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu4} alt="indu4" />{" "}
                </div>
                <h5 className="text-center mt-5">Education</h5>
                <p>
                  {" "}
                  In the education field, Swift IT is engaged in integrated
                  learning system software development, virtual learning
                  management system, LMS software development, and even
                  personalized e-learning software systems. Interactive,
                  convenient, and cost-effective solutions are what we offer at
                  Swift IT.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu5} alt="indu5" />{" "}
                </div>
                <h5 className="text-center mt-5">Banking</h5>
                <p>
                  {" "}
                  From financial software development, custom banking software
                  development, digital banking software services, and custom
                  Fintech development, we at Swift IT extend our software
                  development experience in the industry of banking and finance
                  as well. With custom features, and UI/UX designs we ensure the
                  highest standards and security to the software developed.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu6} alt="indu6" />{" "}
                </div>
                <h5 className="text-center mt-5">Manufacturing</h5>
                <p>
                  {" "}
                  Implement manufacturing software for ensuring quality
                  management and maintenance software including the application
                  of intelligent products that help in accelerating the existing
                  processes and bringing down operational costs.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default UIUXDesign;
