// import Container from 'react-bootstrap/Container';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import Common from "../../layouts/Common";
// import Container from 'react-bootstrap/Container';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import {
  FaArrowRight,
  FaCheckCircle,
  FaClock,
  FaCommentAlt,
  FaCommentDots,
  FaFileAlt,
  FaFileCode,
  FaFileInvoice,
  FaGlobe,
  FaLaptop,
  FaLockOpen,
  FaMobileAlt,
  FaScrewdriver,
  FaSearchengin,
  FaServer,
  FaSolarPanel,
  FaSoundcloud,
  FaUserAlt,
  FaWaveSquare,
} from "react-icons/fa";
import indcom from "../../assets/images/indcom.svg";
import serviceall from "../../assets/images/serviceall.svg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContactForm from "../../components/ContactForm";
import IndustryExperience from "../../components/IndustryExperience";
import RecentWork from "../../components/RecentWork";

const Wordpress = () => {
  const iconList = [
    FaArrowRight,
    FaCheckCircle,
    FaClock,
    FaCommentAlt,
    FaCommentDots,
    FaFileAlt,
    FaFileCode,
    FaFileInvoice,
    FaGlobe,
    FaLaptop,
    FaLockOpen,
    FaMobileAlt,
    FaScrewdriver,
    FaSearchengin,
    FaServer,
    FaSolarPanel,
    FaSoundcloud,
    FaUserAlt,
    FaWaveSquare,
  ];
  const iconList2 = [
    "FaArrowRight",
    "FaCheckCircle",
    "FaClock",
    "FaCommentAlt",
    "FaCommentDots",
    "FaFileAlt",
    "FaFileCode",
    "FaFileInvoice",
    "FaGlobe",
    "FaLaptop",
    "FaLockOpen",
    "FaMobileAlt",
    "FaScrewdriver",
    "FaSearchengin",
    "FaServer",
    "FaSolarPanel",
    "FaSoundcloud",
    "FaUserAlt",
    "FaWaveSquare",
  ];

  const serviceData = {
    title: "Offshore WordPress Development Company in Bangladesh",
    subTitle:
      "Swift IT is one of the top WordPress development companies in Bangladesh. We provide WordPress web development services of the highest quality regardless of pricing, deadline, or size of the project. Using WordPress, Swift IT offers an all-inclusive set of solutions that contains full web design, web development, and data management for organizations, corporations, and institutions of all shapes and sizes.",
    feature: [
      {
        title: "SECURITY & IP PROTECTION",
        icon: "FaLockOpen",
      },
      {
        title: "1 YEARS EXPERIENCE",
        icon: "FaGlobe",
      },
      {
        title: "FLEXIBLE ENGAGEMENT MODELS",
        icon: "FaWaveSquare",
      },
      {
        title: "STRICT NDA TERMS",
        icon: "FaFileAlt",
      },
      {
        title: "NO HIDDEN COSTS",
        icon: "FaFileInvoice",
      },
      {
        title: "ON-TIME PROJECT DELIVERY",
        icon: "FaClock",
      },
    ],
    heading_one: {
      title: "Our WordPress Development Capabilities",
      details:
        "WordPress is the largest and most popular Content Management platform in the world, which powers a whopping 100 million sites worldwide. When we say popular; government institutions, military, banks, Fortune 500 companies, insurance companies, small businesses and bloggers all prefer WordPress CMS. That is the worldwide popularity WordPress owns.",
      image: "https://www.image.com",
    },
    heading_two: {
      title: "Advantages of outsourcing",
      details: "",
      feature_point: [
        {
          title: "",
          icon: "FaCheckCircle",
        },
      ],
      image: "https://www.image.com",
    },
    service_items: {
      title: "Wordpress Development Services",
      subTitle:
        "Being a topnotch WordPress development company in Bangladesh, We facilitate digital presence, intuitive solutions, and quick responses together with our highly experienced development team. We focus on delivering world-class solutions that correspond with different business requirements. We focus on creating your prime differentiation in this digital world of high competition with the most integrated, reliable, and sustainable WordPress development services.",
      image: "https://www.image.com",
      items: [
        {
          title: "Installation And Configuration",
          details:
            "We make the installation and configuration an easy process with our proficient industrial experience. Our development team applies their expertise to untangle the entire process and make it simple.",
        },
        {
          title: "Custom Wordpress Plugin Development",
          details:
            "As per your business needs, our WordPress plugin development services can add indispensable features and functionalities to your WordPress website. Our plugin development service can assist you to subsume custom features to your sites for better performance.",
        },
        {
          title: "Wordpress Website Development Services",
          details:
            "Establish a strong digital presence for your brands or businesses throughout the global markets through WordPress website development services from Swift IT Solutions. Our WordPress developers have hands-on experience delivering feature-packed custom-made websites. We know how a superior website can accelerate your business growth and diligently figure out the latest trends in the market. Thus, our developers can design and develop accordingly.",
        },
        {
          title: "Wordpress Theme And Template",
          details:
            "At Swift IT, we focus on creating professional web solutions with our creativity to provide you with an improved digital presence. Our WordPress theme customization service is a blend of eye-catching designs and efficient functions. Our team can create a professional website that is unique and impressive with a theme of your choice following the industry standards. We pay attention to all your guidelines and adopt best design practices while developing your site.",
        },
        {
          title: "Wordpress Migration",
          details:
            "Migration no longer needs to be a tough task, we can make it easy for you. We can promptly redirect your website to a new host with no loss of data. Our expertise empowers us to boost the complete migration process. Connect with us to convert your current website to an appealing WordPress site in a secure, seamless, and cost-effective way.",
        },
        {
          title: "WordPress Maintenance And Support",
          details:
            "WordPress support and maintenance services are important after developing a high-quality website. We ensure complete security, maintenance, and enhancement of your website. With us, you don't have to get puzzled with the maintenance mode for long. We will work efficiently to update, maintain and monitor your site.",
        },
      ],
    },
  };

  const renderFeature = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <Col lg={6} sm={12} key={index}>
        <div className="technology-list">
          {" "}
          <span>
            <Icon2 />
          </span>
          <label> {data.title} </label>
        </div>
      </Col>
    );
  };

  const renderlist = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <li key={index}>
        {" "}
        <span>
          <Icon2 />
        </span>
        <p>{data.title}</p>
      </li>
    );
  };

  const renderServiceLeft = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <div className="company-slide mission-vission" key={index}>
        <Row>
          <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-left">
              <Icon2 />
            </div>
          </Col>
          <Col md={9} className="ps-0">
            <h5 className="p-0">{data.title}</h5>
            <p className="mb-0"> {data.details} </p>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>{serviceData.title}</strong>
                </h1>
                <p className="text-white p20 mb-4">{serviceData.subTitle}</p>
              </div>
              <Row>
                {serviceData.feature.map((data) => renderFeature(data))}
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="width-full mt-5" src={indcom} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>{serviceData?.heading_one?.title}</h2>
              <p>{serviceData?.heading_one?.details}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>{serviceData.service_items.title}</h2>
              <p> {serviceData.service_items.subTitle} </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[0].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[0].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[1].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[1].details}{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaMobileAlt />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[2].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[2].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[3].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[3].details}{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[4].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[4].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            {serviceData.service_items?.items[5] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[5]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[5]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaSolarPanel />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            {serviceData.service_items?.items[6] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[6]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[6]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaScrewdriver />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            {serviceData.service_items?.items[7] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[7]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[7]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaSoundcloud />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      <RecentWork />
      <IndustryExperience />
    </Common>
  );
};

export default Wordpress;
