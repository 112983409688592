import React, { useRef } from "react";
import Common from "../layouts/Common";
import services from "../../src/assets/images/services.avif";
import erp from "../../src/assets/images/ERP-Software-Development-Services.png";
import crm from "../../src/assets/images/crm (2).png";
import hardware from "../../src/assets/images/hardware.png";
import network from "../../src/assets/images/network.png";
import hospital from "../../src/assets/images/hospital.png";
import website from "../../src/assets/images/website.png";
import { FaBusinessTime } from "react-icons/fa";
import marketing from "../../src/assets/images/digital_marketing.jpg";

const ServiceNew = () => {
  const techSectionRef = useRef(null);
  const scrollOffset = 100;
  const scrollToSection = () => {
    console.log("CLICKEDD");

    const currentPosition =
      techSectionRef.current.getBoundingClientRect().top + window.scrollY;
    console.log("SDAS: ", techSectionRef.current.getBoundingClientRect());

    window.scrollTo({
      top: currentPosition,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Common onGotoSection={scrollToSection}>
        <section className="all-sub-banner portfolio-banner">
          <div className="sub-banner">
            <div className="m-0 row">
              <div>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <div className="slider-banner slide-arrow">
                      <h4 className="h4project">
                        <FaBusinessTime />
                        OUR SERVICES
                      </h4>
                      <div className="text-white">
                        <h1>Services We Provide</h1>
                        <p>
                          We specialize in delivering innovative technology
                          solutions that help businesses scale, optimize, and
                          succeed in today’s fast-paced digital world. Whether
                          you're looking for custom software development,
                          network infrastructure solution our team is here to
                          provide high-quality, scalable, and reliable tech
                          services tailored to your unique needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div>
                      <img
                        src={services}
                        style={{
                          position: "relative",
                          top: "124px",
                          borderRadius: "50%",
                        }}
                        alt="service"
                        width={"100%"}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="port-sec ">
          <h2 className="custom-head-new text-center">SERVICES</h2>
          <div className="container">
            <div className="row mt-5">
              <div
                className="col-md-12 text-center d-flex mt-5 my-3 justify-content-center"
                ref={techSectionRef}
              >
                <div className="our-services-section">
                  <h2 className="mb-0">ERP Software Development</h2>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 align-self-center">
                  <div>
                    <h3> Unlock Efficiency with Our ERP Solutions</h3>
                    <p>
                      Our comprehensive ERP software is designed to streamline
                      your business operations, improve productivity, and drive
                      growth. From inventory management and finance to human
                      resources and customer relations, our integrated system
                      brings all your key processes under one roof. Tailored to
                      meet the unique needs of your business, our ERP solutions
                      offer real-time insights, seamless automation, and
                      enhanced decision-making capabilities. Transform the way
                      you manage your resources, reduce operational costs, and
                      achieve greater efficiency with our cutting-edge ERP
                      software.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <img src={erp} width={"100%"} alt="erp"></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 text-center my-3 d-flex justify-content-center">
                <div className="our-services-section">
                  <h2 className="mb-0">CRM Software Development</h2>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 align-self-center">
                  <div>
                    <h3>
                      {" "}
                      Enhance Customer Relationships with Our CRM Solutions{" "}
                    </h3>
                    <p>
                      Our powerful CRM software helps you build stronger, more
                      meaningful relationships with your customers. By
                      centralizing customer data, tracking interactions, and
                      automating key tasks, our CRM system enables your team to
                      provide personalized service, increase customer
                      satisfaction, and drive loyalty. With real-time insights,
                      streamlined communication, and data-driven
                      decision-making, you can improve sales, marketing, and
                      customer support processes. Whether you're a small
                      business or a large enterprise, our CRM solutions empower
                      you to understand your customers better, foster long-term
                      relationships, and accelerate growth.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-center">
                    <img src={crm} width={"90%"} alt="crm"></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 text-center my-3 d-flex justify-content-center">
                <div className="our-services-section">
                  <h2 className="mb-0">Website Design and Development</h2>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 align-self-center">
                  <div>
                    <h3>
                      {" "}
                      Transform Your Online Presence with Expert Web Design &
                      Development{" "}
                    </h3>
                    <p>
                      At Swit IT Ltd., we specialize in creating visually
                      stunning and highly functional websites that drive
                      engagement and deliver results. Our team of skilled
                      designers and developers work closely with you to
                      understand your brand, goals, and target audience,
                      ensuring a customized web solution that reflects your
                      unique identity. From responsive designs and user-friendly
                      interfaces to seamless navigation and cutting-edge
                      technology, we build websites that not only look great but
                      also perform flawlessly across all devices. Whether you
                      need a simple landing page or a complex e-commerce
                      platform, we deliver websites that are optimized for
                      performance, SEO, and conversion, helping you stand out in
                      the digital world.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-center">
                    <img src={website} width={"80%"} alt="crm"></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 text-center my-3 d-flex justify-content-center">
                <div className="our-services-section">
                  <h2 className="mb-0">Digital Marketing Solution</h2>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 align-self-center">
                  <div>
                    <h3>
                      {" "}
                      Drive Growth with Our Comprehensive Digital Marketing
                      Solutions{" "}
                    </h3>
                    <p>
                      Unlock the full potential of your business with our
                      data-driven digital marketing strategies. At Swit IT Ltd.,
                      we provide end-to-end digital marketing services designed
                      to increase visibility, engage your target audience, and
                      drive measurable results. From SEO and paid advertising to
                      social media marketing and content creation, our team
                      crafts personalized campaigns that align with your brand’s
                      objectives and deliver tangible outcomes. Whether you’re
                      looking to boost traffic, improve conversions, or build
                      brand awareness, our tailored digital marketing solutions
                      help you connect with customers at every stage of their
                      journey, driving long-term success in the digital
                      landscape.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-center">
                    <img
                      src={marketing}
                      width={"70%"}
                      alt="digital marketing"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 text-center my-3 d-flex justify-content-center">
                <div className="our-services-section">
                  <h2 className="mb-0">Networking Service Solution</h2>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 align-self-center">
                  <div>
                    <h3>
                      {" "}
                      Robust Networking Infrastructure Solutions for Seamless
                      Connectivity{" "}
                    </h3>
                    <p>
                      At swift IT Ltd., we specialize in delivering scalable and
                      secure networking infrastructure solutions to ensure your
                      business stays connected and operational at all times.
                      From designing and implementing high-performance networks
                      to providing ongoing maintenance and support, our expert
                      team ensures that your systems are optimized for
                      reliability, speed, and security. Whether you need to
                      upgrade your existing network, deploy new network
                      architecture, or integrate advanced technologies like
                      Wi-Fi, VPNs, or cloud solutions, we provide end-to-end
                      solutions tailored to your specific business needs. Trust
                      us to build a solid foundation for your network that
                      supports growth, enhances productivity, and ensures smooth
                      communication across your organization.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-center">
                    <img src={network} width={"70%"} alt="crm"></img>
                  </div>
                </div>
              </div>
              {/* <div className="row mt-5">
                <div className="col-md-12 text-center my-3 d-flex justify-content-center">
                  <div className="our-services-section">
                    <h2 className="mb-0">
                      Medical College Information Syestem
                    </h2>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6 align-self-center">
                    <div>
                      <h3>
                        {" "}
                        Streamline Administration with Our Medical College
                        Information System{" "}
                      </h3>
                      <p>
                        Our Medical College Information System (MCIS) is a
                        comprehensive, integrated software solution designed to
                        simplify and enhance the management of medical colleges.
                        Tailored to meet the unique needs of educational
                        institutions in the healthcare sector, our MCIS
                        streamlines administrative tasks, student records,
                        faculty management, admissions, scheduling, and exam
                        processes—all from a single, user-friendly platform.
                        With features like real-time reporting, seamless
                        integration with existing systems, and robust data
                        security, our software enables colleges to improve
                        operational efficiency, reduce manual workloads, and
                        provide a more organized learning environment. Whether
                        it's tracking student progress, managing faculty
                        schedules, or maintaining accreditation standards, our
                        MCIS ensures that medical colleges run smoothly and
                        effectively.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center">
                      <img src={hospital} width={"80%"} alt="crm"></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12 text-center my-3 d-flex justify-content-center">
                  <div className="our-services-section">
                    <h2 className="mb-0">
                      Medical College Information Syestem
                    </h2>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6 align-self-center">
                    <div>
                      <h3>
                        {" "}
                        Streamline Administration with Our Medical College
                        Information System{" "}
                      </h3>
                      <p>
                        At Swift IT Ltd., we offer comprehensive hardware
                        solutions to keep your systems running at peak
                        performance. From installation and configuration to
                        repair and maintenance, our expert technicians ensure
                        your hardware operates seamlessly. Whether it's
                        desktops, servers, or networking equipment, we provide
                        reliable, fast, and cost-effective support to meet your
                        business needs.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center">
                      <img src={hardware} width={"80%"} alt="crm"></img>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="row mt-5">
              <div className="col-md-12 text-center my-3 d-flex justify-content-center">
                <div className="our-services-section">
                  <h2 className="mb-0">Hardware Supply Service</h2>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-6 align-self-center">
                  <div>
                    <h3>
                      {" "}
                      From Small Fixes to Major Builds, We’ve Got the Hardware
                      You Need
                    </h3>
                    <p>
                      At swift IT Ltd., we specialize in providing high-quality,
                      reliable hardware supplies for contractors, professionals,
                      tech enthusiasts, and users. Whether you’re embarking on a
                      large-scale project or tackling a simple home improvement,
                      we offer the tools, materials, and expertise to get the
                      job done right.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-center mt-3">
                    <img src={hardware} width={"80%"} alt="hardware"></img>
                  </div>
                </div>
              </div>
              {/* <div className="row mt-5">
                <div className="col-md-12 text-center my-3 d-flex justify-content-center">
                  <div className="our-services-section">
                    <h2 className="mb-0">
                      Medical College Information Syestem
                    </h2>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6 align-self-center">
                    <div>
                      <h3>
                        {" "}
                        Streamline Administration with Our Medical College
                        Information System{" "}
                      </h3>
                      <p>
                        Our Medical College Information System (MCIS) is a
                        comprehensive, integrated software solution designed to
                        simplify and enhance the management of medical colleges.
                        Tailored to meet the unique needs of educational
                        institutions in the healthcare sector, our MCIS
                        streamlines administrative tasks, student records,
                        faculty management, admissions, scheduling, and exam
                        processes—all from a single, user-friendly platform.
                        With features like real-time reporting, seamless
                        integration with existing systems, and robust data
                        security, our software enables colleges to improve
                        operational efficiency, reduce manual workloads, and
                        provide a more organized learning environment. Whether
                        it's tracking student progress, managing faculty
                        schedules, or maintaining accreditation standards, our
                        MCIS ensures that medical colleges run smoothly and
                        effectively.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center">
                      <img src={hospital} width={"80%"} alt="crm"></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12 text-center my-3 d-flex justify-content-center">
                  <div className="our-services-section">
                    <h2 className="mb-0">
                      Medical College Information Syestem
                    </h2>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6 align-self-center">
                    <div>
                      <h3>
                        {" "}
                        Streamline Administration with Our Medical College
                        Information System{" "}
                      </h3>
                      <p>
                        At Swift IT Ltd., we offer comprehensive hardware
                        solutions to keep your systems running at peak
                        performance. From installation and configuration to
                        repair and maintenance, our expert technicians ensure
                        your hardware operates seamlessly. Whether it's
                        desktops, servers, or networking equipment, we provide
                        reliable, fast, and cost-effective support to meet your
                        business needs.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-center">
                      <img src={hardware} width={"80%"} alt="crm"></img>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </Common>
    </>
  );
};

export default ServiceNew;
