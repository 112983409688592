import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { FaHome } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import angular from "../assets/images/ani.svg";
import lara from "../assets/images/lara.svg";
import logo from "../assets/images/Swift-IT.png";
import mong from "../assets/images/mong.svg";
import node from "../assets/images/node.svg";
import pay from "../assets/images/pay.svg";
import php from "../assets/images/php.svg";
import reacjs from "../assets/images/reacjs.svg";
import tech from "../assets/images/tech.svg";
import wez from "../assets/images/wez.svg";
import wp from "../assets/images/wp.svg";
import his from "../assets/images/HIS.png";
import "./Nav.css";

const Navtop = ({ onGotoSection }) => {
  const [stickyClass, setStickyClass] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsOpen(false); // Close the navbar if clicked outside
    }
  };

  // Set up event listener when the component mounts and clean up when it unmounts
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <Navbar
        className={`customHeader ${stickyClass}`}
        expand="lg"
        ref={navbarRef}
      >
        <Container>
          <Row className="">
            <Col md={3} className="nav-grid-cutom">
              <h2 className="navLogo">
                <NavLink className="logohideone" exact to="/home">
                  <img src={logo} alt="logo" />
                </NavLink>
              </h2>
            </Col>
            <Col md={7} className="nav-grid-cutom mt-1">
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={toggleNavbar}
              />
              <Navbar.Collapse id="basic-navbar-nav" in={isOpen}>
                <Nav className="m-auto custom-navbar">
                  <NavLink exact to="/home">
                    {/* <FaHome className="me-1 home-mar" /> */}
                    Home
                  </NavLink>
                  <NavLink exact to="/service">
                    Services
                  </NavLink>
                  {/* <NavDropdown
                    title="Services"
                    id="basic-nav-dropdown"
                    className="all-drop-down drop-down-nav "
                  >
                    <Container className="">
                      <Row className="my-1 margin-left">
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img src={his} alt="HIS" className="mx-1" />{" "}
                              <p className="text-white mt-3">
                                ERP Software Development
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img src={pay} alt="pay" className="mx-1" />{" "}
                              <p className="text-white mt-3">
                                CRM Software Development
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img src={pay} alt="pay" className="mx-1" />{" "}
                              <p className="text-white mt-3">
                                Website Design & Development
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img src={pay} alt="pay" className="mx-1" />{" "}
                              <p className="text-white mt-3">
                                Digital Marketing Solution
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img src={pay} alt="pay" className="mx-1" />{" "}
                              <p className="text-white mt-2">
                                Hardware Supply Service
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img src={pay} alt="pay" className="mx-1" />{" "}
                              <p className="text-white mt-2">
                                Networking Infrastructure Solution
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img src={pay} alt="pay" className="mx-1" />{" "}
                              <p className="text-white mt-2">
                                Medical College Information System (MCIS)
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                      </Row>
                    </Container>
                  </NavDropdown> */}
                  {/* <NavDropdown
                    title="Products"
                    id="basic-nav-dropdown"
                    className="all-drop-down drop-down-nav"
                  >
                    <Container className="">
                      <Row className="my-1 margin-left">
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img src={his} alt="HIS" className="mx-1" />{" "}
                              <p className="text-white mt-3">
                                Hospital Information System (HIS)
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img
                                src={angular}
                                alt="angular"
                                className="mx-1"
                              />{" "}
                              <p className="text-white mt-3">
                                Diagnostic Information System (DIS)
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img
                                src={angular}
                                alt="angular"
                                className="mx-1"
                              />{" "}
                              <p className="text-white mt-3">
                                Laboratory Information System (LIS)
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img
                                src={angular}
                                alt="angular"
                                className="mx-1"
                              />{" "}
                              <p className="text-white mt-3">
                                Pharmacy Management System (PMS)
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img
                                src={angular}
                                alt="angular"
                                className="mx-1"
                              />{" "}
                              <p className="text-white mt-2">
                                Stydy Abroad Management System (SAMS)
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img
                                src={angular}
                                alt="angular"
                                className="mx-1"
                              />{" "}
                              <p className="text-white mt-2">
                                Recruitment Management System (RMS)
                              </p>
                            </div>
                          </NavLink>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img
                                src={angular}
                                alt="angular"
                                className="mx-1"
                              />{" "}
                              <p className="text-white mt-2">
                                Recruitment Management System (RMS)
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <NavLink exact to="/angular" className="blocks">
                            <div className="d-flex align-items-center blocks-img py-1">
                              {" "}
                              <img
                                src={angular}
                                alt="angular"
                                className="mx-1"
                              />{" "}
                              <p className="text-white mt-2">
                                Medical College Information System (MCIS)
                              </p>
                            </div>
                          </NavLink>
                        </Col>
                      </Row>
                    </Container>
                  </NavDropdown> */}
                  {/* <NavDropdown
                    title="Technologies"
                    id="basic-nav-dropdown"
                    className="all-drop-down drop-down-nav"
                  >
                    <Container>
                      <Row>
                        <Col lg={3} sm={6}>
                          <NavLink exact to="/angular">
                            <div className="block-item">
                              {" "}
                              <span>
                                {" "}
                                <img src={angular} alt="angular" />{" "}
                              </span>
                              <p className="text-white">Angularjs</p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={3} sm={6}>
                          <NavLink exact to="/reactjs">
                            <div className="block-item">
                              {" "}
                              <span>
                                {" "}
                                <img src={reacjs} alt="reacjs" />{" "}
                              </span>
                              <p className="text-white">Reactjs</p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={3} sm={6}>
                          <NavLink exact to="/python">
                            <div className="block-item">
                              {" "}
                              <span>
                                {" "}
                                <img src={pay} alt="pay" />{" "}
                              </span>
                              <p className="text-white">Python</p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={3} sm={6}>
                          <NavLink exact to="/aspnet">
                            <div className="block-item">
                              {" "}
                              <span>
                                {" "}
                                <img src={tech} alt="tech" />{" "}
                              </span>
                              <p className="text-white">ASP.NET MVC</p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={3} sm={6}>
                          <NavLink exact to="/vuejs">
                            <div className="block-item">
                              {" "}
                              <span>
                                {" "}
                                <img src={wez} alt="wez" />{" "}
                              </span>
                              <p className="text-white">Vue JS</p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={3} sm={6}>
                          <NavLink exact to="/php">
                            <div className="block-item">
                              {" "}
                              <span>
                                {" "}
                                <img src={php} alt="php" />{" "}
                              </span>
                              <p className="text-white">PHP</p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={3} sm={6}>
                          <NavLink exact to="/nodejs">
                            <div className="block-item">
                              {" "}
                              <span>
                                {" "}
                                <img src={node} alt="node" />{" "}
                              </span>
                              <p className="text-white">Node.js</p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={3} sm={6}>
                          <NavLink exact to="/laravel">
                            <div className="block-item">
                              {" "}
                              <span>
                                {" "}
                                <img src={lara} alt="lara" />{" "}
                              </span>
                              <p className="text-white">Laravel</p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={3} sm={6}>
                          <NavLink exact to="/magento">
                            <div className="block-item">
                              {" "}
                              <span>
                                {" "}
                                <img src={mong} alt="mong" />{" "}
                              </span>
                              <p className="text-white">Magento</p>
                            </div>
                          </NavLink>
                        </Col>
                        <Col lg={3} sm={6}>
                          <NavLink exact to="/wordpress">
                            <div className="block-item">
                              {" "}
                              <span>
                                {" "}
                                <img src={wp} alt="wp" />{" "}
                              </span>
                              <p className="text-white">Wordpress</p>
                            </div>
                          </NavLink>
                        </Col>
                      </Row>
                    </Container>
                  </NavDropdown> */}

                  <NavLink exact to="/products">
                    Products
                  </NavLink>
                  <NavLink exact to="/about-us">
                    About Us
                  </NavLink>
                  <NavLink exact to="/team">
                    Our Team
                  </NavLink>
                  {/* <NavDropdown
                    title="About US"
                    id="basic-nav-dropdown"
                    className="common-drop-down drop-down-nav custom-navbar-list"
                  >
                    <Row>
                      <Col lg={12}>
                        <NavDropdown.Item>
                          <NavLink exact to="/about-us">
                            About Us
                          </NavLink>
                          <NavLink exact to="/team">
                            Our Team
                          </NavLink>
                        </NavDropdown.Item>
                      </Col>
                    </Row>
                  </NavDropdown> */}
                  <NavLink className="activenone" exact to="/contact">
                    <Col md={2} className="navButton dis-hide-show">
                      <Button variant="primary btn-custom rounded mt-1 ps-4 pe-4">
                        Get In Touch
                      </Button>
                    </Col>
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </Col>
            <Col md={2} className="navButton activenone dis-none mt-1 text-end">
              <NavLink className="activenone" exact to="/contact">
                <Button variant="primary btn-custom rounded mt-1 px-4">
                  Get In Touch
                </Button>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  );
};

export default Navtop;
