import React from "react";
import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";
import leftimg from "../../assets/images/leftimg.svg";
import as from "../../assets/images/as.svg";
import bs from "../../assets/images/bs.svg";
import coma1 from "../../assets/images/coma1.png";
import coma2 from "../../assets/images/coma2.png";
import coma3 from "../../assets/images/coma3.png";
import coma4 from "../../assets/images/coma4.jpg";
import coma5 from "../../assets/images/coma5.jpg";
import coma6 from "../../assets/images/coma6.png";
import com1 from "../../assets/images/coma.png";
import com2 from "../../assets/images/comb.png";
import com3 from "../../assets/images/comc.png";
import aboutall from "../../assets/images/aboutall.svg";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Aboutus = () => {
  const options3 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <Common>
      <section className="all-sub-banner portfolio-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow"></div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="port-sec mt-299">
        <div className="all-company-feature pt-2">
          <Container>
            <Row>
              <Col
                lg={6}
                md={12}
                sm={12}
                className="mb-4"
                data-aos="fade-right"
              >
                <h2 className="mt-4">Who We Are </h2>
                <p>
                  At Swift IT, we believe in the power of technology to
                  transform businesses. As a software company specializing in IT
                  solutions and services, we help businesses of all sizes
                  leverage technology to improve their operations and increase
                  their efficiency.
                  <br />
                  Since our inception, we have been committed to providing our
                  clients with the best possible software solutions and
                  services. We understand that every business is unique, and we
                  work closely with our clients to understand their specific
                  needs and requirements. This allows us to develop customized
                  software solutions that are tailored to the unique needs of
                  each business.
                </p>
              </Col>
              <Col lg={6} md={12} sm={12} className="mb-4" data-aos="fade-left">
                <img className="abouimg mt-4" src={aboutall} alt="aboutall" />
              </Col>
            </Row>
          </Container>
        </div>

        <section className="all-company-feature pt-3">
          <Container>
            <Row>
              <Col md={6} className="mt-4 mb-4" data-aos="fade-right">
                <div className="company-block-feature-two">
                  {" "}
                  <img src={leftimg} alt="leftimg" />{" "}
                </div>
              </Col>
              <Col md={6} className="mt-5 mb-4" data-aos="fade-left">
                <h2>Engineering Excellence</h2>
                <p>
                  With our 7+ years of experience in integrating next-gen
                  technologies into businesses, we have created various
                  applications that are performing well and helping businesses
                  one-up their competition.
                </p>
                <h2>Our Vision</h2>
                <p>
                  To be a leading software development company recognized for
                  delevering innovative and high-quality solutions that empower
                  businessess to achieve their goals and enhance their
                  efficiency in an increasingly digital world.
                </p>

                <h2>Our Mission</h2>
                <p>
                  As Swift IT Ltd., our misssion is to provide exceptional
                  software development services that meet the unique needs of
                  our clients. We are committed to leveraging the latest
                  technologies and industry best practices to deliver scalable,
                  reliable and user-friendly software solutions. Through
                  collaboration, continuous improvement and a customer-centric
                  approach, we aim to foster long-term partnerships with our
                  clients, helping them to navigate the complexities of
                  technology and drive their success.
                </p>
                {/* <p>
                  Founded in 2005, our offshore IT services are globally
                  recognized for calibrating solutions & innovative structuring.
                </p>

                <h2 className="mt-5">Our Infrastructure</h2>
                <p>
                  Based in the heart of USA, Bangladesh, Swift IT Ltd. has
                  state-of-the-art infrastructural facilities powered with
                  latest hardware and software. Our agile infrastructure coupled
                  with cloud computing make it perfect for outsourcing. We have
                  separate labs for different technologies such as: Web
                  Development, Mobile App Development, Research & Development,
                  Designing, Testing, and other support departments for Admin,
                  HR, Finance, etc. This advanced infrastructure unit helps us
                  to deliver only the best-in-class services according to varied
                  business needs. Since our inception, we have invested in
                  organic growth; building on our engineering capabilities,
                  organizational processes, and culture required to deliver a
                  truly collaborative ecosystem for solving technology
                  challenges. At the core of Swift IT lies a culture rooted in
                  innovation, learning, and a result-oriented mindset.
                </p> */}
                {/* <Row>
                  <div
                    className="company-slide mission-vission mt-4 abt-block"
                    data-aos="flip-left"
                  >
                    <Row>
                      <Col md={3}>
                        <div className="slider-icon">
                          {" "}
                          <img src={as} alt="as" />{" "}
                        </div>
                      </Col>
                      <Col md={9} className="ps-0">
                        <h5 className="p-0">Our Vision & Mission</h5>
                        <p className="mb-0">
                          {" "}
                          Across the globe, the Enterprise ecosystem is
                          evolving. This has created a new scenario for
                          businesses operating in the market…{" "}
                        </p>
                        <NavLink exact to="/vision">
                          Read more...
                        </NavLink>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="company-slide mission-vission mt-4"
                    data-aos="flip-right"
                  >
                    <Row>
                      <Col md={9} className="pe-0 text-end">
                        <h5 className="p-0">Our Mission</h5>
                        <p className="mb-0">
                          {" "}
                          SAcross the globe, the Enterprise ecosystem is
                          evolving. This has created a new scenario for
                          businesses operating in the market. You either have
                          robus…{" "}
                        </p>
                        <NavLink exact to="/mission">
                          Read more...
                        </NavLink>
                      </Col>
                      <Col md={3}>
                        <div className="slider-icon">
                          {" "}
                          <img src={bs} alt="bs" />{" "}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row> */}
              </Col>

              {/* <Col md={12} className="text-center pt-5 mb-4 mt-5">
                <h2>A Glimpse Of Our Office</h2>
                <p className="text-center mb-5">
                  Swift IT Solutions Pvt. Ltd. has a total of 4 offices that are
                  located in the United States, Bangladesh, Australia, <br />
                  and UK. Here’s a collection of photographs from our offshore
                  development center at Kochi, Bangladesh.
                </p>
              </Col> */}

              {/* <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={coma1} alt="coma1" />{" "}
                </div>
              </Col>
              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={coma2} alt="coma2" />{" "}
                </div>
              </Col>
              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={coma3} alt="coma3" />{" "}
                </div>
              </Col>
              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={coma4} alt="coma4" />{" "}
                </div>
              </Col>
              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={coma5} alt="coma5" />{" "}
                </div>
              </Col>
              <Col md={4} data-aos="flip-up">
                <div className="portfolio-com">
                  {" "}
                  <img src={coma6} alt="coma6" />{" "}
                </div>
              </Col> */}
            </Row>
          </Container>
        </section>
      </section>
      {/* 
      <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-5 pt-3 certification-slide">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="flip-up">
              <h2>Our Clients</h2>
              <p className="pe-5">
                Over the last 16+ years, we have worked with businesses of all
                sizes and complexities around the world - from small and
                medium-sized businesses to top well-known enterprises. At
                present, we have expertise in serving multiple industries
                including Aviation, Banking, Education, Finance, Healthcare,
                Insurance, Logistics, Manufacturing, Retail, Transportation,
                etc.
              </p>
            </Col>
            <Col md={6} className="mt-0 mb-4">
              <OwlCarousel
                className="owl-theme slider-custom slider-custom-two"
                {...options3}
                loop
                margin={20}
                nav
              >
                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={com1} alt="com1" />{" "}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={com2} alt="com2" />{" "}
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={com3} alt="com3" />{" "}
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={com3} alt="com3" />{" "}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={com1} alt="com1" />{" "}
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="company-slide">
                    <div className="slider-icon2">
                      {" "}
                      <img src={com2} alt="com2" />{" "}
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section> */}
    </Common>
  );
};

export default Aboutus;
