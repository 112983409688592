import React from "react";
import Common from "../../layouts/Common";
// import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/Form";
import {
  FaArrowRight,
  FaCheckCircle,
  FaClock,
  FaCommentAlt,
  FaCommentDots,
  FaFileAlt,
  FaFileCode,
  FaFileInvoice,
  FaGlobe,
  FaLaptop,
  FaLockOpen,
  FaMobileAlt,
  FaScrewdriver,
  FaSearchengin,
  FaServer,
  FaSolarPanel,
  FaSoundcloud,
  FaUserAlt,
  FaWaveSquare,
} from "react-icons/fa";
// import Carousel from 'react-bootstrap/Carousel';
// import project1 from '../../assets/images/project-1.jpg';
// import project2 from '../../assets/images/project-2.jpg';
// import project3 from '../../assets/images/project-3.jpg';
// import project4 from '../../assets/images/project-4.jpg';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import about from "../../assets/images/about.svg";
import indcom from "../../assets/images/indcom.svg";
import indu1 from "../../assets/images/indu1.svg";
import indu2 from "../../assets/images/indu2.svg";
import indu3 from "../../assets/images/indu3.svg";
import indu4 from "../../assets/images/indu4.svg";
import indu5 from "../../assets/images/indu5.svg";
import indu6 from "../../assets/images/indu6.svg";
import serviceall from "../../assets/images/serviceall.svg";
import worka from "../../assets/images/worka.png";
import workb from "../../assets/images/workb.png";
import workc from "../../assets/images/workc.png";
import workd from "../../assets/images/workd.png";
import worke from "../../assets/images/worke.png";
import workf from "../../assets/images/workf.png";

const Development = () => {
  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>
                    Full Stack Development Services in
                    <br />
                    Bangladesh{" "}
                  </strong>
                </h1>
                <p className="text-white p20 mb-4">
                  We have a talented pool of resources who can work
                  independently and possess all necessary abilities to turn your
                  ideas and ambitions into reality. Our experts provide
                  full-stack development services, which comprise front-end
                  development, backend development, database management, and
                  everything else required for full development environment. We
                  are experts in every area of development and make certain that
                  the result is lucrative and effective. We are highly skilled
                  in developing reliable and high-quality full-stack solutions
                  that accomplish clients' business demands.{" "}
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaLockOpen />
                    </span>
                    <label> Access To Certified Developers </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaGlobe />
                    </span>
                    <label> Highly Customized Solutions </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaWaveSquare />
                    </span>
                    <label> On-Time Delivery </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileAlt />
                    </span>
                    <label> 95% Client Retention </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileInvoice />
                    </span>
                    <label> Transparent Billing Process </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaClock />
                    </span>
                    <label> No Contract Lock-Ins </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <div className="get-touch-form">
                <Form>
                  <div className="mb-3 me-0 sch-block">
                    <h3 className="h3-schedule mb-1">
                      {" "}
                      <strong>Lets Discuss Your Project</strong>{" "}
                    </h3>
                    <p className="mb-5">
                      Guaranteed Response within One Business Day!
                    </p>
                  </div>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaUserAlt />
                    </span>
                    <Form.Control placeholder="Name" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re" controlId="">
                    {" "}
                    <span>
                      <FaCommentAlt />
                    </span>
                    <Form.Control type="email" placeholder="Email" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaMobileAlt />
                    </span>
                    <Form.Control type="text" placeholder="Contact Number" />
                  </Form.Group>
                  <Form.Group
                    className="mb-4 pos-re"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {" "}
                    <span className="comspan">
                      <FaCommentDots />
                    </span>
                    <Form.Control
                      as="textarea"
                      className="textform"
                      placeholder="Comment"
                      rows={2}
                    />
                  </Form.Group>
                  <Col md={12} className="text-center mt-4">
                    <Button variant="primary btn-custom mt-3 btn-big">
                      Submit
                      <FaArrowRight className="ms-5" />
                    </Button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="width-full mt-5" src={indcom} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>Our Full Stack Services</h2>
              <p>
                {" "}
                Our developers have in-depth understanding of different roles
                and technologies, therefore our clients save a significant
                amount of money and time in getting software solutions. We are
                experienced in building high-performing full-stack solutions for
                your startup, enterprises, and large-scale businesses.{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>Our Achievements </h2>
              {/* <p> <strong>Advantages Of Offshore Software Development
          Outsourcing In Bangladesh With Us.</strong> </p> */}
              <ul className="list-inline list-group ul-list-about mt-4">
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>3M+ Development Hours</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>98% Ontime Delivery</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>1 - 3 Days Onboarding Time</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>750+ Happy Customers</p>
                </li>
              </ul>
            </Col>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="abouimg mt-5" src={about} alt="about" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>Our Expertise In Full Stack Development</h2>
              <p>
                {" "}
                We are highly skilled in every layer of software development and
                can integrate each other effectively to complete the project on
                schedule. As we have knowledge in every layer one person is
                capable of handling frontend development, backend development,
                and database management and reducing development costs. We can
                easily troubleshoot issues so that we can optimize applications
                by giving long-term solutions. Since we are experts in every
                aspect and stage of development, we are capable of providing
                high-quality software solutions tailored to your specific
                requirements.{" "}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">UI/UX Development</h5>
                    <p className="mb-0">
                      {" "}
                      We are experts in developing UI/UX designs that are highly
                      interactive and provide great user experience. We combine
                      our technical expertise with business strategy to
                      comprehend your goals and vision, in order to build
                      high-quality UI/UX as per your requirements. We deliver
                      unique UI/UX development services and build software
                      solutions that synchronize with your corporate culture.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">API Development</h5>
                    <p className="mb-0">
                      {" "}
                      Our API development services aim at integrating the
                      software applications with payment systems, email
                      marketing, social networks, business tools and other
                      processes so that Apps can seamlessly interface with both
                      external and internal systems. By leveraging technologies
                      like Nodejs, PHP etc we build custom APIs and use them to
                      integrate your applications with different processes and
                      business systems. With our high-performance APIs, we are
                      able to provide additional functionality to the main
                      applications as per clients' requirements.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Front End Development</h5>
                    <p className="mb-0">
                      {" "}
                      We are experts at filling the gap between look and
                      functionality to provide smart user experience. We are
                      well versed with various front end technologies like HTML,
                      CSS/SCSS, JavaScript, Bootstrap, React, React Native,
                      Angular, Vue.js etc that help us to offer websites that
                      possess great look and high performance. We make sure that
                      our front end development services merge properly with
                      other technologies used in development to deliver unique
                      and best solutions that suit your needs.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Back End Development</h5>
                    <p className="mb-0">
                      {" "}
                      We develop reliable and secure server backends that are
                      efficient to retrieve data from many sources and compile
                      it into a specific web interface. We use different
                      technologies like Python, Java, .Net, PHP etc to develop
                      backend architecture that helps the generation of dynamic
                      web pages. We are experts in building robust and secure
                      backend components for software applications of various
                      complexity, according to your specific requirements.{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Database Development</h5>
                    <p className="mb-0">
                      {" "}
                      We are highly skilled in database development and provide
                      high-quality and reliable data management and storage
                      solutions that meet your needs. We use different database
                      management systems like MS-SQL, My-SQL, MongoDB etc, by
                      considering the nature of dataset that needs to be stored.
                      We are efficient enough to provide right database
                      solutions to customers of all business types.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Full Stack Consulting Services</h5>
                    <p className="mb-0">
                      {" "}
                      We provide you with the best Full Stack consulting
                      services that can transform your business concepts into
                      reliable and high-quality software solutions. We provide
                      roadmaps, workflows, wireframes and features of your
                      project to developers, which help them to build
                      high-performing Full Stack applications.{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSolarPanel />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Migration And Upgradation Services</h5>
                    <p className="mb-0">
                      {" "}
                      Our Full Stack migration experts assist you in making
                      migration plans and help you migrate your legacy
                      application to new platform. We are skilled in upgrading
                      your existing applications to their latest version to
                      provide high-performance software solutions as per your
                      needs.{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaScrewdriver />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Support And Maintenance</h5>
                    <p className="mb-0">
                      {" "}
                      We provide end-to-end support and maintenance services, by
                      updating and testing your application using latest
                      technologies. Our Full Stack maintenance engineers are
                      experts in delivering bug-free and feature-rich
                      applications that meet your requirements.{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSoundcloud />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>
                What Makes Swift IT Your Trusted Partner In Full Stack
                Development?
              </h2>
              <p>
                {" "}
                We deliver Full-Stack apps development services for
                organisations ranging from startups to corporations with
                high-end technological skills and domain understanding.{" "}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Dedicated Project Team</h5>
                    <p className="mb-0">
                      {" "}
                      We have dedicated resources who can work on every layer of
                      development process and can deliver end-to-end software
                      solutions. We are well versed with all layers, so one
                      person do development, testing, optimization etc and could
                      deliver reliable, high-quality and cost-effective
                      applications.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Technical Proficiency</h5>
                    <p className="mb-0">
                      {" "}
                      We are highly experienced and well-versed with technology
                      to deliver high-quality applications that suit your needs.
                      We have knowledge in all layers of development process and
                      could deliver optimized applications in a timely manner.{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Guaranteed Assistance</h5>
                    <p className="mb-0">
                      {" "}
                      We assist you in sharing your business concepts and help
                      you increase the productivity of your organization by
                      providing feature-rich and reliable software solutions. We
                      help you improve software performance by providing
                      post-delivery maintenance so that you can compete with
                      changing trends in industry.{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Advanced Toolkit</h5>
                    <p className="mb-0">
                      {" "}
                      We leverage up-to-date technology and reliable security
                      measures throughout the development cycle to provide you
                      with high-performance and feature-rich applications.{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSolarPanel />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Budget-Friendly</h5>
                    <p className="mb-0">
                      {" "}
                      We analyse your requirements deeply and incorporate our
                      technical knowledge to provide you with cost-effective
                      software solutions. We are capable of building Full Stack
                      solutions for clients of all business types within their
                      budget.{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaScrewdriver />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Tailor-Made Applications</h5>
                    <p className="mb-0">
                      {" "}
                      We communicate with you effectively to gather data and use
                      it efficiently to develop full-stack applications that
                      meet your specific business requirements.{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSoundcloud />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel
              className="owl-theme slider-custom"
              {...options2}
              loop
              margin={20}
              nav
            >
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worka}
                    alt="worka"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workb}
                    alt="workb"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workc}
                    alt="workc"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workd}
                    alt="workd"
                  />{" "}
                </div>
              </div>

              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worke}
                    alt="worke"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workf}
                    alt="workf"
                  />{" "}
                </div>
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
              <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu1} alt="indu1" />{" "}
                </div>
                <h5 className="text-center mt-5">Ecommerce</h5>
                <p>
                  {" "}
                  We deliver user-friendly eCommerce solutions that improve your
                  digital customers' purchasing experience with the help of
                  secure and responsive web pages. We offer cutting-edge online
                  stores to businesses of all types.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu2} alt="indu2" />{" "}
                </div>
                <h5 className="text-center mt-5">Healthcare</h5>
                <p>
                  {" "}
                  We provide superior Full Stack app development services to
                  healthcare professionals in order to enhance their whole
                  system and improve patient satisfaction. We are experts in
                  providing custom applications that improve medical treatment
                  by simplifying and accelerating numerous processes in health
                  sectors.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu3} alt="indu3" />{" "}
                </div>
                <h5 className="text-center mt-5">Aviation</h5>
                <p>
                  We are skilled in developing software solutions that can
                  automate complex aviation systems. We analyse your business
                  needs thoroughly and build digital solutions that accomplish
                  your industry goals and increase productivity.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu4} alt="indu4" />{" "}
                </div>
                <h5 className="text-center mt-5">Education</h5>
                <p>
                  {" "}
                  We are experts in developing advanced class management systems
                  including course management, attendance management, library
                  management, performance evaluation and other services needed
                  for your educational institute. We provide cost-effective
                  e-learning websites that help students a lot in their learning
                  purposes.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu5} alt="indu5" />{" "}
                </div>
                <h5 className="text-center mt-5">Travel</h5>
                <p>
                  {" "}
                  We are efficient in building first-in-class travel app
                  solutions for you, that is capable of captivating new
                  customers and retaining the existing ones. We offer
                  feature-rich and highly functional travel app that includes
                  facilities like e-ticketing, hotel booking, payment gateways
                  etc that are required for a smooth journey.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu6} alt="indu6" />{" "}
                </div>
                <h5 className="text-center mt-5">Media and Entertainment</h5>
                <p>
                  {" "}
                  We provide custom Media and Entertainment software solutions
                  that can enhance your operational efficiency and increase your
                  target audience. We deliver high-quality applications to Media
                  and Entertainment industry and gaming industry by leveraging
                  latest technologies.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default Development;
