import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";
import Common from "../layouts/Common";
import lethello from "../assets/images/lethello.svg";
import contactus from "../assets/images/contactus.svg";
import usa from "../assets/images/usa.webp";
import { FaPeriscope } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import bd from "../assets/images/bd.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaCommentAlt } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";

const Contact = () => {
  return (
    <Common>
      <section className="all-header"></section>

      <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-3 pt-3 bg-white certification-slide blog-wr">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4">
              <section className="all-company-feature pt-2">
                <Container>
                  <Row>
                    <Col md={6} className="mb-4" data-aos="fade-right">
                      <div className="block-hello">
                        <h2 className="mt-3">Let's Talk Business!</h2>
                        <p>
                          <strong>
                            Have questions about your idea? Drop in your details
                            to discuss with our solution consultants. We look
                            forward to hear from you.
                          </strong>
                        </p>
                      </div>
                    </Col>
                    <Col md={6} className="mt-5 mb-4" data-aos="fade-left">
                      <img
                        className="abouimg width-100 mt-4"
                        src={lethello}
                        alt="lethello"
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
            </Col>
          </Row>
        </Container>

        <div className="contact-bg bg-gray bg-deep-gray">
          <Container>
            <Col md={12} className="text-start ps-3"></Col>
            <Row>
              <Col
                md={6}
                className="p-0 left-contact rounded"
                data-aos="fade-right"
              >
                <iframe
                  className="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d789.3040059040793!2d90.37382266954408!3d23.757371598666634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bf54aeecae9d%3A0x5f5a4e02f6d77e84!2s2%2C%201%20Block%20-%20A%2C%20Dhaka%201205!5e1!3m2!1sen!2sbd!4v1731238186127!5m2!1sen!2sbd"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </Col>
              <Col
                md={6}
                className="mb-4 p-5 right-contact"
                data-aos="fade-right"
              >
                <h2 className="mt-0 mb-4">Our Offices</h2>
                {/* <div className='head-ofc-area'>
                        <img src={usa} alt="usa"  className='allflag' />
                  
                    <ul className='list-group list-inline company-list-link'>
                    <h4>United States</h4>
                    <li className='mt-0'> <span>
          <FaPeriscope/>
          </span>
          <label className='text-black'> 28-07 Jackson Ave, Long Island City, NY, 11101, USA </label>
        </li>
        <li className='mt-0'><span>
          <FaMobileAlt/>
          </span>
          <label className='text-black'> (332) 203-6066</label>
        </li>
      </ul>
                        </div> */}

                <div className="head-ofc-area mt-2">
                  <ul className="list-group list-inline company-list-link">
                    <h4>Our Office</h4>
                    <li className="mt-0">
                      {" "}
                      <span>
                        <FaPeriscope />
                      </span>
                      <label className="text-black">
                        4th floor, House No-2/1 , Block-A, Lalmatia,
                        <br />
                        Dhaka-1205
                      </label>
                    </li>
                    <li className="mt-0">
                      <span>
                        <FaMobileAlt />
                      </span>
                      <label className="text-black"> +880 1770-772626</label>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          {/* <Row>
                <Col md={6} className="mb-4 p-5" data-aos="fade-right">
                    <div className='address'>
                    <img src={usa} alt="usa"  className='allflag' />
                    <h3>United States</h3>
                    <ul className='list-group list-inline company-list-link'>
        <li> <span>
          <FaPeriscope/>
          </span>
          <label className='text-black'> 28-07 Jackson Ave, Long Island City, NY, 11101, USA </label>
        </li>
        <li> <span>
          <FaMobileAlt/>
          </span>
          <label className='text-black'> (332) 203-6066</label>
        </li>
      </ul>
                    </div>
                </Col>
                <Col md={6} className="mb-4 p-5" data-aos="fade-right">
                    <div className='address'>
                    <img src={bd} alt="usa"  className='allflag' />
                    <h3>Bangladesh</h3>
                    <ul className='list-group list-inline company-list-link'>
        <li> <span>
          <FaPeriscope/>
          </span>
          <label className='text-black'> CASABELLA house no -17 , road no- 128, gulshan-1, Dhaka-1212 </label>
        </li>
        <li> <span>
          <FaMobileAlt/>
          </span>
          <label className='text-black'> +880 1791001818</label>
        </li>
      </ul>
                    </div>
                </Col>
                </Row> */}

          <Row className="border-form mt-5 pt-5" id="formall">
            <Col md={6} className="p-4 bg-form rounded-5" data-aos="fade-right">
              <div className="get-touch-form border-top-0">
                <Form>
                  <div className="mb-3 me-0 sch-block">
                    <h3 className="h3-schedule mb-5 mt-5">
                      {" "}
                      <strong>Leave Us A Message</strong>{" "}
                    </h3>
                  </div>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaUserAlt />
                    </span>
                    <Form.Control placeholder="Name" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re" controlId="">
                    {" "}
                    <span>
                      <FaCommentAlt />
                    </span>
                    <Form.Control type="email" placeholder="Email" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaMobileAlt />
                    </span>
                    <Form.Control type="text" placeholder="Contact Number" />
                  </Form.Group>

                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaBuilding />
                    </span>
                    <Form.Control type="text" placeholder="Subject" />
                  </Form.Group>
                  <Form.Group
                    className="mb-4 pos-re"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {" "}
                    <span className="comspan">
                      <FaCommentDots />
                    </span>
                    <Form.Control
                      as="textarea"
                      className="textform"
                      placeholder="Comment"
                      rows={2}
                    />
                  </Form.Group>
                  <Col md={12} className="text-center mt-4">
                    <Button variant="primary btn-custom mt-3 btn-big">
                      Submit
                      <FaArrowRight className="ms-5" />
                    </Button>
                  </Col>
                </Form>
              </div>
            </Col>

            <Col md={6} className="bg-white " data-aos="fade-right">
              <img
                className="width96 mt-2 mb-4"
                src={contactus}
                alt="contactus"
              />
              <h3 className="ps-5 ms-5">
                We'd Love To
                <br />
                Hear From You
              </h3>
              <p className="ps-5 ms-5">
                Have questions about your idea? Drop in your details to discuss
                with our solution consultants. We look forward to hear from you.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default Contact;
