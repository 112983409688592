import React from "react";
import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { FaLockOpen } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaWaveSquare } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaSlideshare } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";
import hire from "../../assets/images/hire.svg";
import soft from "../../assets/images/soft.svg";
import appd from "../../assets/images/appd.svg";
import sof1 from "../../assets/images/sof1.svg";
import carta from "../../assets/images/carta.svg";
import content from "../../assets/images/content.svg";
import { FaStarOfLife } from "react-icons/fa";
import { FaScribd } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import worka from "../../assets/images/worka.png";
import workb from "../../assets/images/workb.png";
import workc from "../../assets/images/workc.png";
import workd from "../../assets/images/workd.png";
import worke from "../../assets/images/worke.png";
import workf from "../../assets/images/workf.png";
import indu1 from "../../assets/images/indu1.svg";
import indu2 from "../../assets/images/indu2.svg";
import indu3 from "../../assets/images/indu3.svg";
import indu4 from "../../assets/images/indu4.svg";
import indu5 from "../../assets/images/indu5.svg";
import indu6 from "../../assets/images/indu6.svg";
import expertise from "../../assets/images/expertise-maintenance-img.svg";

import maintenancesupportimg from "../../assets/images/maintenance-support-img.svg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const SoftwareMaintenance = () => {
  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>
                    Software Maintenance and <br />
                    Support Services in Bangladesh{" "}
                  </strong>
                </h1>
                <p className="text-white p20 mb-4">
                  At Swift IT, we can provide you with end-to-end software
                  maintenance and support services to keep your software
                  solutions up to date. We are successfully serving clients
                  across the world to improve their business productivity and
                  ensure high-performance software applications are in place.
                  Our skilled professionals study the current application’s
                  functionalities and issues met by the client and establish
                  consistent methodologies to enhance the system. We deliver
                  24*7 software maintenance services so that you can get in
                  touch with us anytime with your queries and change requests to
                  make your software robust and secure.
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaLockOpen />
                    </span>
                    <label> Security & IP Protection </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaGlobe />
                    </span>
                    <label> 5 Years Experience </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaWaveSquare />
                    </span>
                    <label> Flexible Engagement Models </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileAlt />
                    </span>
                    <label> Strict NDA Terms </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileInvoice />
                    </span>
                    <label> No Hidden Costs </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaClock />
                    </span>
                    <label> On-Time Project Delivery </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <div className="get-touch-form">
                <Form>
                  <div className="mb-3 me-0 sch-block">
                    <h3 className="h3-schedule mb-1">
                      {" "}
                      <strong>Lets Discuss Your Project</strong>{" "}
                    </h3>
                    <p className="mb-5">
                      Guaranteed Response within One Business Day!
                    </p>
                  </div>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaUserAlt />
                    </span>
                    <Form.Control placeholder="Name" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re" controlId="">
                    {" "}
                    <span>
                      <FaCommentAlt />
                    </span>
                    <Form.Control type="email" placeholder="Email" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaMobileAlt />
                    </span>
                    <Form.Control type="text" placeholder="Contact Number" />
                  </Form.Group>
                  <Form.Group
                    className="mb-4 pos-re"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {" "}
                    <span className="comspan">
                      <FaCommentDots />
                    </span>
                    <Form.Control
                      as="textarea"
                      className="textform"
                      placeholder="Comment"
                      rows={2}
                    />
                  </Form.Group>
                  <Col md={12} className="text-center mt-4">
                    <Button variant="primary btn-custom mt-3 btn-big">
                      Submit
                      <FaArrowRight className="ms-5" />
                    </Button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our Software Maintenance & Support Services</h2>
              <p>
                While enhancing the functionality, we will ensure that your
                applications run with complete efficiency as per your
                requirements. Our engineers are able to elevate your
                organisation’s growth by providing high-quality applications
                that can successfully convert your business statistics. We will
                help you modernise your system with our reliable support and
                software product maintenance services that can assure your
                success in every step.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaStarOfLife />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      Adaptive Software Maintenance And Product Services
                    </h5>
                    <p className="mb-0">
                      We have solid expertise in detecting and fixing bugs in
                      software applications and maintaining them as quality
                      products. We handle errors and faults of software
                      applications and eradicate bugs in design, logic, and
                      code. We efficiently execute corrective repairs to
                      eliminate glitches that can badly affect the performance
                      of the software. With our corrective maintenance services,
                      we will identify all the bugs before your customers do,
                      and fix them effectively so that your brand image gets
                      improved.
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">
                      Corrective Software Maintenance And Support
                    </h5>
                    <p className="mb-0">
                      Enjoy stable infrastructure and less downtime by
                      harnessing our cloud automation services. We will
                      configure a continuous delivery pipeline and automate
                      provisioning and deployment to enable rapid delivery.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSlideshare />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img
                className="img-width"
                src={maintenancesupportimg}
                alt="serviceall"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaScribd />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      Perfective Software Maintenance Services
                    </h5>
                    <p className="mb-0">
                      {" "}
                      Our skilled software developers can help you implement
                      evolving requirements of your software systems. We can
                      integrate new features into your apps and remove
                      non-functional features from them to improve interactivity
                      with customers. We will refine your software applications
                      through modifications, rectifications, editing, deletions,
                      additions, and enhancements to rectify
                      performance-stalling issues and enhance productivity. We
                      will provide reliable transformative functionalities that
                      can modernise your system and help you achieve end goals.
                      .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">
                      Preventive Software Maintenance And Support
                    </h5>
                    <p className="mb-0">
                      {" "}
                      We can focus on making changes to your software
                      application so that it will be suitable for your future
                      requirements. Our services include optimizing code and
                      updating documentation based on customers’ feedback and
                      past occurrences. We are intent to include solutions that
                      will meet future needs and solve issues that may arise in
                      the long run. We can help you prevent the deterioration of
                      your software by updating the software environment and
                      thus elevating your business performance.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSlideshare />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
            <img className="width-full mt-5" src={expertise} alt="indcom" />
          </Col>
          <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
            <h2 className="mt-5 pt-4">
              Our Expertise In
              <br />
              Software Maintenance & Support Services
            </h2>
            <p>
              Our talented pool of resources has vast knowledge and experience
              in software maintenance and support services. We are proficient in
              various legacy platforms including language & database skills and
              are capable of using different third-party technologies. We
              leverage state-of-the-art tools and technologies to modernize your
              legacy system so that it can meet evolving business needs. We are
              adept at fixing bugs in the applications and provide our clients
              with optimised applications without any glitches. We can help you
              fine-tune your existing application with attractive pricing
              solutions and in a timely manner.
            </p>
          </Col>
        </Row>
      </Container>

      <section className="amt-5 pt-5 bg-blackall">
        <Container>
          <h2 className="text-white mt-4">
            What Makes Swift IT Your <br />
            Trusted Partner in Software Maintenance and Support Services?
          </h2>
          <p className="text-white">
            We deliver Software Maintenance & Support Services for organisations
            ranging from startups to corporations with our high-end technical
            skills and domain understanding.
          </p>
          <Row className="soft-block">
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={hire} alt="hire" />
                </span>
                <h5 className="text-center mt-5">Dedicated Project Team</h5>
                <p>
                  {" "}
                  We have dedicated resources who will be working for you to
                  optimise your obsolete system according to evolving business
                  needs. Our development team is committed to delivering
                  scalable, bug-free, and secure software applications on
                  schedule.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={content} alt="content" />
                </span>
                <h5 className="text-center mt-5">Advanced Toolkit</h5>
                <p>
                  To modernize your system, we will leverage all of the latest
                  and most advanced tools & techniques available in the
                  industry. To ensure that the application is highly-functional
                  and without any glitches, our team employs advanced security
                  measures and uses the most up-to-date testing techniques.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={soft} alt="soft" />
                </span>
                <h5 className="text-center mt-5">Technical Proficiency</h5>
                <p>
                  We can assist you in achieving your business objectives by
                  providing reliable support and maintenance services. We can
                  enhance your software applications according to your specific
                  business demands.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={sof1} alt="sof1" />
                </span>
                <h5 className="text-center mt-5">Budget-Friendly</h5>
                <p>
                  We do an in-depth analysis of the requirements in order to
                  precisely scope the project. We have provided software
                  maintenance and support services for a range of enterprises
                  across numerous sectors while staying within their budget and
                  industry standards.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={appd} alt="appd" />
                </span>
                <h5 className="text-center mt-5">Guaranteed Assistance</h5>
                <p>
                  Our analysts will assist you in refining your business data
                  and scoping the project to ensure that it is both
                  cost-effective and completed in a timely manner. Our support
                  engineers will provide maintenance and technical assistance to
                  optimize and enhance your legacy software solutions.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={carta} alt="carta" />
                </span>
                <h5 className="text-center mt-5">E-Commerce</h5>
                <p>
                  We transform your business idea into an online store to make
                  it reach globally to your target customers increasing your
                  revenue.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel
              className="owl-theme slider-custom"
              {...options2}
              loop
              margin={20}
              nav
            >
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worka}
                    alt="worka"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workb}
                    alt="workb"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workc}
                    alt="workc"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workd}
                    alt="workd"
                  />{" "}
                </div>
              </div>

              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worke}
                    alt="worke"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workf}
                    alt="workf"
                  />{" "}
                </div>
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
              <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu1} alt="indu1" />{" "}
                </div>
                <h5 className="text-center mt-5">Ecommerce</h5>
                <p>
                  {" "}
                  The e-commerce solutions offered at Swift IT are on-the-go and
                  assured to bring benefits with increased ROI and overall
                  productivity. We provide custom e-commerce development
                  services and develop web apps for any kind of business across
                  various industries and enterprises ranging from B2B and B2C
                  trading networks to small, middle-sized, and large businesses.
                  These custom solutions meet the user’s requirements by
                  applying specific areas of specialization.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu2} alt="indu2" />{" "}
                </div>
                <h5 className="text-center mt-5">Healthcare</h5>
                <p>
                  {" "}
                  At Swift IT, we help you develop healthcare software
                  applications that automate processes such as patient
                  management, lab management, test result analysis, and data
                  collection. For the medical technology companies, from
                  consulting to maintenance and support we always lent our
                  supporting hands to tackle your technological dilemmas.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu3} alt="indu3" />{" "}
                </div>
                <h5 className="text-center mt-5">Logistics</h5>
                <p>
                  The experts at Swift IT help you build innovative and
                  impressive tech-enabled logistics software applications to
                  streamline the supply chain and provide flawless customer
                  support. The warehouse management software also makes sure to
                  enhance warehouse efficiency. The expert logistics and
                  transportation management software offered by Swift IT thereby
                  help you do your tasks more effectively.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu4} alt="indu4" />{" "}
                </div>
                <h5 className="text-center mt-5">Education</h5>
                <p>
                  {" "}
                  In the education field, Swift IT is engaged in integrated
                  learning system software development, virtual learning
                  management system, LMS software development, and even
                  personalized e-learning software systems. Interactive,
                  convenient, and cost-effective solutions are what we offer at
                  Swift IT.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu5} alt="indu5" />{" "}
                </div>
                <h5 className="text-center mt-5">Banking</h5>
                <p>
                  {" "}
                  From financial software development, custom banking software
                  development, digital banking software services, and custom
                  Fintech development, we at Swift IT extend our software
                  development experience in the industry of banking and finance
                  as well. With custom features, and UI/UX designs we ensure the
                  highest standards and security to the software developed.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu6} alt="indu6" />{" "}
                </div>
                <h5 className="text-center mt-5">Manufacturing</h5>
                <p>
                  {" "}
                  Implement manufacturing software for ensuring quality
                  management and maintenance software including the application
                  of intelligent products that help in accelerating the existing
                  processes and bringing down operational costs.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default SoftwareMaintenance;
