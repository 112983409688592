import React from "react";
import pay from "../../assets/images/pay.svg";
import angular from "../../assets/images/ani.svg";
import homepage from "../../assets/images/homepage.png";
import classifiedad from "../../assets/images/classified-ad-project.jpg";
import vexanimeproject from "../../assets/images/vexanimeproject.jpg";
import reacjs from "../../assets/images/reacjs.svg";
import adminpanelreact from "../../assets/images/adminpanelreact.jpg";
import socialuidproject from "../../assets/images/social-uid-project.jpg";
import zoomclone from "../../assets/images/zoomclone.jpg";
import ScreenwritingProject from "../../assets/images/ScreenwritingProject.jpg";
import radioproject from "../../assets/images/radioproject.jpg";
import veni from "../../assets/images/veni.png";
import screenwritingproject2 from "../../assets/images/screenwritingproject2.jpg";
import Core_Logo from "../../assets/images/NET_Core_Logo.svg";
import mongo from "../../assets/images/mongo.svg";
import js from "../../assets/images/js.png";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import java from "../../assets/images/java.png";
import spring from "../../assets/images/spring-boot-icon.png";
import oracle from "../../assets/images/oracle.png";
import mysql from "../../assets/images/mysql.png";
import docker from "../../assets/images/docker.png";
import redux from "../../assets/images/redux.png";
import security from "../../assets/images/security.png";
import micro from "../../assets/images/micro.png";
import cmcProduct from "../../assets/images/cmc_Product.png";
import diagnostic from "../../assets/images/diagnostic_Product.png";
import pharmacy from "../../assets/images/pharmacy_Product.png";
import pacific from "../../assets/images/pacific_Product.png";
import recruiting from "../../assets/images/recruiting_Product.png";
import medical from "../../assets/images/medical_Product.png";
import lis from "../../assets/images/lis.png";

const Webapp = () => {
  return (
    <div className="ps-4">
      <Row className="mt-5">
        <Col lg={5} md={12} className="col-padding mb-4">
          <div className="img-padding">
            <img
              src={cmcProduct}
              className="img-fluid-new"
              alt="ScreenwritingProject"
            />
            {/* <div className="slider-icon2">
              <img
                src={cmcProduct}
                className="portimg"
                alt="ScreenwritingProject"
              />{" "}
            </div> */}
          </div>
        </Col>
        <Col lg={7} md={12} className="mt-3">
          <h2 className="mt-2 mb-3">Hospital Information System</h2>
          <h6 className="fw-normal">
            {" "}
            This Hospital Information System (HIS) is an integrated software
            platform designed to manage various aspects of a hospital or
            healthcare facility's operations, including patient care,
            administrative tasks, medical records, and financial processes. The
            HIS ensures that all hospital departments work cohesively, enhancing
            operational efficiency and improving the quality of care delivered
            to patients.
          </h6>
          <div className="modules">
            <h5>Modules</h5>
            <section className="d-flex justify-content-start">
              <ul>
                <li>Patient Registration</li>
                <li>Electronic Medical Records (EMR)</li>
                <li>IPD</li>
                <li>OPD</li>
                <li>Emergency</li>
                <li>Pharmacy</li>
                <li>Nurse Station</li>
              </ul>

              <ul>
                <li>LIS</li>
                <li>RIS</li>
                <li>HR</li>
                <li>Accounts</li>
                <li>Financial Reports</li>
                <li>Reports Delivery</li>
              </ul>
            </section>
          </div>
          {/*
    <Col md={12} className="text-start mt-4">
    <NavLink exact to="/blog-details">
      <Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
      <FaArrowRight className="ms-5" />
      </Button>
    </NavLink>
    </Col>
    */}
        </Col>

        <Col lg={12} sm={12} md={12} className="">
          <h4 className="mt-4 text-black text-center mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={java} alt="java" />
            </span>
            <p>Java</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={oracle} alt="oracle" />
            </span>
            <p>Oracle</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={spring} alt="spring-boot" />
            </span>
            <p>Spring Boot</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={redux} alt="redux" />
            </span>
            <p>Redux</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={security} alt="spring security" />
            </span>
            <p>Spring Security</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={docker} alt="docker" />
            </span>
            <p>Docker</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={micro} alt="micro service" />
            </span>
            <p>Micro Service</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={5} md={12} className="col-padding mb-4">
          <div className="img-padding">
            <img
              src={diagnostic}
              className="img-fluid-new"
              alt="vexanimeproject"
            />{" "}
          </div>
          {/* <div className="company-slide">
            <div className="slider-icon2">
              <img src={diagnostic} className="img-fluid-new" alt="vexanimeproject" />{" "}
            </div>
          </div> */}
        </Col>
        <Col lg={7} md={12} className="mt-3">
          <h2 className="mt-2 mb-3">Diagnostic Information System</h2>
          <h6 className="fw-normal">
            {" "}
            This Diagnostic Information System (DIS) is a specialized type of
            healthcare information system that focuses on managing and
            processing diagnostic data within a healthcare facility. It is
            designed to assist healthcare providers in performing diagnostic
            tests, managing test results, and ensuring efficient communication
            between the laboratory, radiology departments, and clinicians. This
            system plays a critical role in diagnosing diseases, monitoring
            patient conditions, and guiding treatment decisions.
          </h6>
          <div className="modules">
            <h5>Modules</h5>
            <section className="d-flex justify-content-start">
              <ul>
                <li>Patient Registration</li>
                <li>OPD</li>
                <li>LIS</li>
                <li>RIS</li>
              </ul>

              <ul>
                <li>Reports Delivery</li>
                <li>Financial Reports</li>
              </ul>
            </section>
          </div>
        </Col>
        <Col lg={12} sm={12} md={12} className="">
          <h4 className="mt-4 text-black text-center mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={java} alt="java" />
            </span>
            <p>Java</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={oracle} alt="oracle" />
            </span>
            <p>Oracle</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={spring} alt="spring-boot" />
            </span>
            <p>Spring Boot</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={redux} alt="redux" />
            </span>
            <p>Redux</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={security} alt="spring security" />
            </span>
            <p>Spring Security</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={docker} alt="docker" />
            </span>
            <p>Docker</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={micro} alt="micro service" />
            </span>
            <p>Micro Service</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg={5} md={12} className="col-padding mb-4 ">
          <div className="img-padding">
            <img src={lis} className="img-fluid-new" alt="lis" />{" "}
          </div>
          {/* <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={socialuidproject}
                className="portimg"
                alt="social-uid-project"
              />{" "}
            </div>
          </div> */}
        </Col>
        <Col lg={7} md={12} className="mt-3">
          <h2 className="mt-2 mb-3">Laboratory Information System</h2>
          <h6 className="fw-normal">
            This Laboratory Information System (LIS) is a specialized software
            system used by laboratories, particularly in healthcare settings, to
            manage and process laboratory tests, diagnostic data, and clinical
            information. It helps streamline laboratory workflows, improves
            accuracy, and ensures timely delivery of test results. An LIS is
            typically integrated with other hospital systems such as the
            Hospital Information System (HIS), Electronic Health Records (EHR),
            and Clinical Information Systems (CIS) to ensure a seamless flow of
            data between healthcare providers.
          </h6>
          <div className="modules">
            <h5>Modules</h5>
            <section className="d-flex justify-content-start">
              <ul>
                <li>Sample Management</li>
                <li>Order Management</li>
                <li>Test Processing</li>
                <li>Inventory and Reagent Management</li>
              </ul>

              <ul>
                <li>Barcode Integration in All Aspects</li>
                <li>Data Management</li>
                <li>Reports Delivery</li>
                <li>Financial Reports</li>
              </ul>
            </section>
          </div>
          {/*
    <Col md={12} className="text-start mt-4">
    <NavLink exact to="/blog-details">
      <Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
      <FaArrowRight className="ms-5" />
      </Button>
    </NavLink>
    </Col>
    */}
        </Col>
        <Col lg={12} sm={12} md={12} className="">
          <h4 className="mt-4 text-black text-center mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={java} alt="java" />
            </span>
            <p>Java</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={oracle} alt="oracle" />
            </span>
            <p>Oracle</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={spring} alt="spring-boot" />
            </span>
            <p>Spring Boot</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={redux} alt="redux" />
            </span>
            <p>Redux</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={security} alt="spring security" />
            </span>
            <p>Spring Security</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={docker} alt="docker" />
            </span>
            <p>Docker</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={micro} alt="micro service" />
            </span>
            <p>Micro Service</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={5} md={12} className="col-padding mb-4 ">
          <div className="img-padding">
            <img src={pharmacy} className="img-fluid-new" alt="zoomclone" />{" "}
          </div>
          {/* <div className="company-slide">
            <div className="slider-icon2">
              <img src={pharmacy} className="portimg" alt="classifiedad" />{" "}
            </div>
          </div> */}
        </Col>
        <Col lg={7} md={12} className="mt-3">
          <h2 className="mt-2 mb-3">Pharmacy Management System</h2>
          <h6 className="fw-normal">
            {" "}
            This Pharmacy Management System (PMS) is an integrated software
            solution designed to streamline and automate the management of
            pharmaceutical operations within healthcare facilities, including
            hospitals, clinics, and retail pharmacies. The primary goal of a PMS
            is to improve the accuracy, efficiency, and safety of pharmacy
            processes, from inventory management and medication dispensing to
            patient safety and regulatory compliance.
          </h6>
          <div className="modules">
            <h5>Modules</h5>
            <section className="d-flex justify-content-start">
              <ul>
                <li>Inventory Management</li>
                <li>Billing Management</li>
                <li>Operation</li>
              </ul>

              <ul>
                <li>Supplier & Manufacturer Management</li>
                <li>Invoice Management</li>
                <li>Financial Reports</li>
              </ul>
            </section>
          </div>
        </Col>
        <Col lg={12} sm={12} md={12} className="">
          <h4 className="mt-4 text-black text-center mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={java} alt="java" />
            </span>
            <p>Java</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={oracle} alt="oracle" />
            </span>
            <p>Oracle</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={spring} alt="spring-boot" />
            </span>
            <p>Spring Boot</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={redux} alt="redux" />
            </span>
            <p>Redux</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={security} alt="spring security" />
            </span>
            <p>Spring Security</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={docker} alt="docker" />
            </span>
            <p>Docker</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={micro} alt="micro service" />
            </span>
            <p>Micro Service</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg={5} md={12} className="col-padding mb-4">
          <div className="img-padding">
            <img src={medical} className="img-fluid-new" alt="medical" />{" "}
          </div>
          {/* <div className="company-slide">
            <div className="slider-icon2">
              <img src={medical} className="portimg" alt="medical" />{" "}
            </div>
          </div> */}
        </Col>
        <Col lg={7} md={12} className="mt-3">
          <h2 className="mt-2 mb-3">Medical College Information System</h2>
          <h6 className="fw-normal">
            {" "}
            This Medical College Information System (MCIS) is like the "central
            nervous system" for a medical college—it ties together all the
            essential operations, workflows, and data needed to run the
            institution efficiently. Think of it as the brain that controls
            various aspects of medical education, administration, faculty,
            student, and patient care data, ensuring everything works in
            harmony. The goal of a Medical College Information System is to
            improve operational efficiency, enhance communication, support
            academic excellence, ensure regulatory compliance, and provide
            data-driven insights for decision-making.
          </h6>
          <div className="modules">
            <h5>Modules</h5>
            <section className="d-flex justify-content-start">
              <ul>
                <li>Student Admission & Dashboard</li>
                <li>Hostel Management</li>
                <li>Prof. Exam Management</li>
                <li>Student Routine & Notice</li>
              </ul>

              <ul>
                <li>Library</li>
                <li>Financial Reports</li>
                <li>Accounts</li>
              </ul>
            </section>
          </div>
          {/*
    <Col md={12} className="text-start mt-4">
    <NavLink exact to="/blog-details">
      <Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
      <FaArrowRight className="ms-5" />
      </Button>
    </NavLink>
    </Col>
    */}
        </Col>
        <Col lg={12} sm={12} md={12} className="">
          <h4 className="mt-4 text-black text-center mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={java} alt="java" />
            </span>
            <p>Java</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={oracle} alt="oracle" />
            </span>
            <p>Oracle</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={spring} alt="spring-boot" />
            </span>
            <p>Spring Boot</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={redux} alt="redux" />
            </span>
            <p>Redux</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={security} alt="spring security" />
            </span>
            <p>Spring Security</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={docker} alt="docker" />
            </span>
            <p>Docker</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={micro} alt="micro service" />
            </span>
            <p>Micro Service</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg={5} md={12} className="col-padding mb-4">
          <div className="img-padding">
            <img src={recruiting} className="img-fluid-new" alt="zoomclone" />{" "}
          </div>
          {/* <div className="company-slide">
            <div className="slider-icon2">
              <img src={recruiting} className="portimg" alt="zoomclone" />{" "}
            </div>
          </div> */}
        </Col>
        <Col lg={7} md={12} className="mt-3">
          <h2 className="mt-2 mb-3">Recruiting Management System</h2>
          <h6 className="fw-normal">
            {" "}
            This Recruiting Management System (RMS) for Sending People Abroad is
            a specialized software solution designed to streamline and automate
            the recruitment process for organizations that send workers,
            professionals, or students abroad for employment, education, or
            other purposes. This system is particularly useful for recruitment
            agencies, migration consultants, staffing firms, and overseas
            employment companies that facilitate international placements. The
            goal of such a system is to simplify the complex processes involved
            in recruiting candidates for foreign jobs, including screening,
            documentation, visa applications, international job placements,
            compliance with immigration laws, and post-placement support.
          </h6>
          <div className="modules">
            <h5>Modules</h5>
            <section className="d-flex justify-content-start">
              <ul>
                <li>Applicant & Agent Registration</li>
                <li>Billing Management</li>
                <li>Payment Service Mapping</li>
              </ul>

              <ul>
                <li>Accounts</li>
                <li>Financial Reports</li>
                <li>Reports</li>
              </ul>
            </section>
          </div>
        </Col>
        <Col lg={12} sm={12} md={12} className="">
          <h4 className="mt-4 text-black text-center mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={java} alt="java" />
            </span>
            <p>Java</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={mysql} alt="mysql" />
            </span>
            <p>My Sql</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={spring} alt="spring-boot" />
            </span>
            <p>Spring Boot</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={redux} alt="redux" />
            </span>
            <p>Redux</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={security} alt="spring security" />
            </span>
            <p>Spring Security</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={docker} alt="docker" />
            </span>
            <p>Docker</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={micro} alt="micro service" />
            </span>
            <p>Micro Service</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={5} md={12} className="col-padding mb-4 ">
          <div className="img-padding">
            <img src={pacific} className="img-fluid-new" alt="pacific" />{" "}
          </div>
          {/* <div className="company-slide">
            <div className="slider-icon2">
              <img src={pacific} className="portimg" alt="adminpanelreact" />{" "}
            </div>
          </div> */}
        </Col>
        <Col lg={7} md={12} className="mt-3">
          <h2 className="mt-2 mb-3">
            Study Abroad Consultancy and Education Management System
          </h2>
          <h6 className="fw-normal">
            This Education Consultancy System (ECS) is a specialized software
            platform designed to assist education consultants, agencies, and
            institutions in managing the complex processes involved in advising
            students on academic opportunities, including admissions,
            scholarships, visa applications, test preparation, and career
            counseling. It helps streamline operations, improve communication
            between stakeholders, and enhance the overall student experience.
            The system is typically used by education consultants, study abroad
            agencies, career counseling centers, scholarship providers, and
            universities that manage student recruitment and admissions for
            international or domestic academic programs.
          </h6>
          <div className="modules">
            <h5>Modules</h5>
            <section className="d-flex justify-content-start">
              <ul>
                <li>Applicant & Agent Registration</li>
                <li>Billing Management</li>
                <li>Visa & Ticketing</li>
              </ul>

              <ul>
                <li>Accounts</li>
                <li>Financial Reports</li>
                <li>Reports</li>
              </ul>
            </section>
          </div>
          {/*
    <Col md={12} className="text-start mt-4">
    <NavLink exact to="/blog-details">
      <Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
      <FaArrowRight className="ms-5" />
      </Button>
    </NavLink>
    </Col>
    */}
        </Col>
        <Col lg={12} sm={12} md={12} className="">
          <h4 className="mt-4 text-black text-center mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={java} alt="java" />
            </span>
            <p>Java</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={mysql} alt="mysql" />
            </span>
            <p>My Sql</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={spring} alt="spring-boot" />
            </span>
            <p>Spring Boot</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={redux} alt="redux" />
            </span>
            <p>Redux</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={security} alt="spring security" />
            </span>
            <p>Spring Security</p>
          </div>

          <div className="blog-tech">
            {" "}
            <span>
              <img src={docker} alt="docker" />
            </span>
            <p>Docker</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={micro} alt="micro service" />
            </span>
            <p>Micro Service</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>

      {/* <Row className="mt-5 pt-5">
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img src={radioproject} className="portimg" alt="radioproject" />{" "}
            </div>
          </div>
        </Col>
        <Col md={7} className="mt-3 ps-5">
          <h2 className="mt-5">Radio Project</h2>
          <p className="pe-5">
            The project Radio Go was built using React and Python, offering
            users access to over 2000 Greek radio stations featuring various
            genres of music, talk shows, and sports coverage. Its use of React
            allowed for a fast and responsive user experience, while Python
            provided a reliable infrastructure for managing and organizing the
            collection of stations. The platform's search and filtering features
            make it easy for users to find the perfect station for their
            listening preferences.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={reacjs} alt="reacjs" />
            </span>
            <p>Reactjs</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={pay} alt="pay" />
            </span>
            <p>Django</p>
          </div>
          <div className="clearfix"></div>
        </Col>
      </Row>
      <Row className="mt-5 pt-5">
        <Col md={7} className="mt-3">
          <h2 className="mt-5">Screenwriting Project</h2>
          <p className="pe-5">
            The project was a web-based application that I built using vanilla
            JavaScript. It allowed users to create scripts for dramas, movies,
            or other productions. It featured customizable templates, automatic
            formatting, and a built-in script analysis tool for refining and
            perfecting scripts. Users could create character profiles, manage
            scenes, and collaborate with a team in real time. The project
            provided an easy-to-use platform for writers of all skill levels,
            with features that streamlined the writing process and ensured
            accuracy.
          </p>
          <h4 className="mt-4 text-black mb-3">
            <strong>Technologies Used</strong>
          </h4>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={js} alt="js" />
            </span>
            <p>Javascript</p>
          </div>
          <div className="blog-tech">
            {" "}
            <span>
              <img src={veni} alt="veni" />
            </span>
            <p>Vanilla </p>
          </div>

          <div className="clearfix"></div>
          
    <Col md={12} className="text-start mt-4">
    <NavLink exact to="/blog-details">
      <Button variant="primary btn-custom mt-3 btn-big w260 w190">View Project
      <FaArrowRight className="ms-5" />
      </Button>
    </NavLink>
    </Col>
   
        </Col>
        <Col md={5} className="mt-4 mb-4 blog-all">
          <div className="company-slide">
            <div className="slider-icon2">
              <img
                src={screenwritingproject2}
                className="portimg"
                alt="screenwritingproject2"
              />{" "}
            </div>
          </div>
        </Col>
      </Row> */}
    </div>
  );
};

export default Webapp;
