// import Container from 'react-bootstrap/Container';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import Common from "../../layouts/Common";
// import Container from 'react-bootstrap/Container';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import {
  FaArrowRight,
  FaCheckCircle,
  FaClock,
  FaCommentAlt,
  FaCommentDots,
  FaFileAlt,
  FaFileCode,
  FaFileInvoice,
  FaGlobe,
  FaLaptop,
  FaLockOpen,
  FaMobileAlt,
  FaScrewdriver,
  FaSearchengin,
  FaServer,
  FaSolarPanel,
  FaSoundcloud,
  FaUserAlt,
  FaWaveSquare,
} from "react-icons/fa";
import indcom from "../../assets/images/indcom.svg";
import serviceall from "../../assets/images/serviceall.svg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContactForm from "../../components/ContactForm";
import IndustryExperience from "../../components/IndustryExperience";
import RecentWork from "../../components/RecentWork";

const Magento = () => {
  const iconList = [
    FaArrowRight,
    FaCheckCircle,
    FaClock,
    FaCommentAlt,
    FaCommentDots,
    FaFileAlt,
    FaFileCode,
    FaFileInvoice,
    FaGlobe,
    FaLaptop,
    FaLockOpen,
    FaMobileAlt,
    FaScrewdriver,
    FaSearchengin,
    FaServer,
    FaSolarPanel,
    FaSoundcloud,
    FaUserAlt,
    FaWaveSquare,
  ];
  const iconList2 = [
    "FaArrowRight",
    "FaCheckCircle",
    "FaClock",
    "FaCommentAlt",
    "FaCommentDots",
    "FaFileAlt",
    "FaFileCode",
    "FaFileInvoice",
    "FaGlobe",
    "FaLaptop",
    "FaLockOpen",
    "FaMobileAlt",
    "FaScrewdriver",
    "FaSearchengin",
    "FaServer",
    "FaSolarPanel",
    "FaSoundcloud",
    "FaUserAlt",
    "FaWaveSquare",
  ];

  const serviceData = {
    title: "Magento E-Commerce Development Company in Bangladesh",
    subTitle:
      "Whatever be your ecommerce plan, hiring Magento developers from Swift IT can deliver you all sorts of customized Magento ecommerce development services for any scale and any industry.Let’s join hands As a Magento ecommerce development company in Bangladesh, it will be our pleasure to be part of your ecommerce success by providing you the active support with 100% satisfaction for your Magento ecommerce development services.",
    feature: [
      {
        title: "SECURITY & IP PROTECTION",
        icon: "FaLockOpen",
      },
      {
        title: "1 YEARS EXPERIENCE",
        icon: "FaGlobe",
      },
      {
        title: "FLEXIBLE ENGAGEMENT MODELS",
        icon: "FaWaveSquare",
      },
      {
        title: "STRICT NDA TERMS",
        icon: "FaFileAlt",
      },
      {
        title: "NO HIDDEN COSTS",
        icon: "FaFileInvoice",
      },
      {
        title: "ON-TIME PROJECT DELIVERY",
        icon: "FaClock",
      },
    ],
    heading_one: {
      title: "Magento E-Commerce Development Services",
      details:
        "Are you excited about the opportunity that ecommerce throws in your way and planning for the same then choose us to deliver you the best of Magento ecommerce development services. We are Swift IT Solutions, a renowned Magento development company in Bangladesh having experience of several years successfully delivered and servicing clients all over the world for magento development solutions. We are one of the top software outsourcing companies in Bangladesh to provide Magento e-commerce development services globally. Having a team of expert Bangladeshn Magento developers carry out top-notch Magento ecommerce development projects developed for robust performance and optimum results delivering outstanding results in the actual marketplace. Hire Magento developer in Bangladesh from our team and get the best of professional for your magneto job. Whether it is building a B2B online store or B2C shopping cart based website, our Magento development team have vast experience in creating all sorts of virtual shops with an accomplished track record of offering customized Magento ecommerce development services.",
      image: "https://www.image.com",
    },
    heading_two: {
      title: "Advantages of outsourcing",
      details: "",
      feature_point: [
        {
          title: "",
          icon: "FaCheckCircle",
        },
      ],
      image: "https://www.image.com",
    },
    service_items: {
      title: "Our Magento Services",
      subTitle:
        "Swift IT offers incredible Magento development services in Bangladesh with assured high responsiveness, navigation, and user-friendliness. As a reputed Magento development company in Bangladesh, we can help transform your user experience with our brilliant Magento development services. Given below are our exclusive Magento development services in Bangladesh:",
      image: "https://www.image.com",
      items: [
        {
          title: "E-Commerce Store Development",
          details:
            "We have proven expertise in creating feature-rich and functionally superb B2B and B2C e-commerce applications from scratch. Our professionals can help you with your e-commerce challenges by adding bespoke features to your website. We utilize the potential of the Magento platform to build highly customized e-stores that meet specific client requirements to perfection. We use the latest tools and techniques to craft high-performance Magento e-store development solutions and can assist you to improve your business performance.",
        },
        {
          title: "Theme Development",
          details:
            "We will create custom themes for your Magento store to ensure that it meets all of your business goals and specifications and closely matches your business concept. We can assist you in giving your store a unique appeal that will better attract your target customers and result in more traffic and conversions. Through our Magento theme development services, we build responsive themes that can adapt to different screen sizes & types while remaining mobile-friendly. We develop custom themes in the most efficient manner and with the most distinctive strategies and designs.",
        },
        {
          title: "Magento Extension Development",
          details:
            "We have years of experience in building custom Magento extensions, so we can add marvelous features to your online store. We can deliver business-specific extensions to empower your software solutions by making them more user-friendly and organized. Through our Magento Extension development services, we can provide your online store with superior functionality, easy navigation, and the finest buying experience possible in order to keep ahead of the competition. Our magento web development team works hard to fulfil our clients' needs in a timely and responsible manner.",
        },
        {
          title: "Third-Party Integration",
          details:
            "Our experts can offer Magento integration solutions to increase the efficiency of your web stores by enabling them to communicate with other third-party software like ERPs, payment gateways, shipment providers, CRMs, SMS gateway, etc. Through our efficient and advanced third-party integration services, you can provide your customers with secure and smooth payment processes. We will implement third-party integrations to offer a seamless user experience to your shoppers. We make every effort to deliver the most productive and cost-effective solutions that satisfy and exceed our clients' expectations.",
        },
        {
          title: "Magento Consultation Service",
          details:
            "Our Magento technology consultants can answer any questions you have about Magento design and development and assist you in implementing your projects. We find problems you didn't even know you had and propose simple, elegant remedies that don't require a complete revamp of your store. We can advise you on the best build to deploy to match your business and technical goals, ensuring excellent performance, stability, security, and scalability. Our Magento strategy professionals can also give guidance on ways to improve conversion rates, increase revenues, optimize the checkout process, and much more.",
        },
        {
          title: "Support And Maintenance",
          details:
            "We will offer comprehensive support and maintenance services to make sure that your store is always up-to-date, secure, scalable, and fully optimized to meet your changing business needs. We will use state-of-the-art tools and techniques to deliver effective maintenance solutions in order to improve your business performance and competitive positioning. We can help you remove glitches in your apps and implement relevant methodologies to enhance your system functionality. Our Magento team can provide unrivaled support to ensure that your Magento store delivers a streamlined and seamless shopping experience.",
        },
        {
          title: "Migration And Upgradation",
          details:
            "With the help of our talented Magento developers, we can migrate your existing website to a robust Magento e-commerce platform. We can provide smooth migration services to enhance the functionality of your e-store without affecting the live store environment. We can help you upgrade your current Magento e-store to its latest version in order to deliver a better experience for customers. We can offer best-in-class migration and upgradation services to revamp your legacy system and increase your operational efficiency.",
        },
        {
          title: "Marketplace Integration",
          details:
            "We have a team of Magento specialists who have worked on a wide range of Magento marketplace projects. We provide enterprise-class marketplace solutions that enable you to turn your eCommerce store into a fully functional multi-vendor marketplace with many merchants and customers transacting. We can implement marketplace integration to help your company reach new audiences while also increasing income. We have extensive experience in establishing multi-vendor marketplaces that are known for their security and user-friendliness.",
        },
      ],
    },
  };

  const renderFeature = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <Col lg={6} sm={12} key={index}>
        <div className="technology-list">
          {" "}
          <span>
            <Icon2 />
          </span>
          <label> {data.title} </label>
        </div>
      </Col>
    );
  };

  const renderlist = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <li key={index}>
        {" "}
        <span>
          <Icon2 />
        </span>
        <p>{data.title}</p>
      </li>
    );
  };

  const renderServiceLeft = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <div className="company-slide mission-vission" key={index}>
        <Row>
          <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-left">
              <Icon2 />
            </div>
          </Col>
          <Col md={9} className="ps-0">
            <h5 className="p-0">{data.title}</h5>
            <p className="mb-0"> {data.details} </p>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>{serviceData.title}</strong>
                </h1>
                <p className="text-white p20 mb-4">{serviceData.subTitle}</p>
              </div>
              <Row>
                {serviceData.feature.map((data) => renderFeature(data))}
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="width-full mt-5" src={indcom} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>{serviceData?.heading_one?.title}</h2>
              <p>{serviceData?.heading_one?.details}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>{serviceData.service_items.title}</h2>
              <p> {serviceData.service_items.subTitle} </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[0].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[0].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[1].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[1].details}{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaMobileAlt />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[2].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[2].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[3].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[3].details}{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[4].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[4].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            {serviceData.service_items?.items[5] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[5]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[5]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaSolarPanel />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            {serviceData.service_items?.items[6] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[6]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[6]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaScrewdriver />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            {serviceData.service_items?.items[7] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[7]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[7]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaSoundcloud />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      <RecentWork />
      <IndustryExperience />
    </Common>
  );
};

export default Magento;
