import React from "react";
import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/Container";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import avater from "../../assets/images/avater.svg";
import neephat from "../../assets/images/neephat1.png";
import masum from "../../assets/images/masum.png";
import mim from "../../assets/images/mim.png";
import nazmul from "../../assets/images/nazmul.jpeg";
import mujib from "../../assets/images/mujib.png";
import sadman from "../../assets/images/sadman.png";
const Team = () => {
  return (
    <Common>
      <section className="all-header"></section>

      <section className="all-company-feature bg-deep-gray bg-deep-gray2 mt-3 pt-3 bg-white certification-slide blog-wr">
        <Container>
          <Row>
            <Col md={12} className="mt-5 mb-4 text-center zindex-all">
              <h2 className="member-h2">MEET THE LEADERSHIP</h2>
              <h5 className="text-center mt-3 peopele-h5">
                The People That Propel Us Forward
              </h5>
              <p className="text-center mt-3">
                Swift IT is led by a team of passionate, bold, and creative
                people. Meet our cross-cultural team of IT & Engineering
                professionals who ensure the success of your operations.
                <br /> Discover how our strong team of experienced and competent
                leaders can create a difference for your business.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mtp mt-5">
        <Container>
          <Row className="my-3 justify-content-center">
            <Col lg={3} md={4} sm={12} data-aos="zoom-in">
              <div className="team-member-block px-3">
                <img src={sadman} alt="sadman" className="client-av" />
                <h3> Muhammad Sadman Saquib</h3>
                <p className="text-center">
                  <strong>Managing Director</strong>
                </p>

                <div className="team-social m-0 d-flex">
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaFacebookF />
                    </Button>
                  </NavLink>
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaTwitter />
                    </Button>
                  </NavLink>

                  <Button variant="btn border">
                    <a href="https://www.linkedin.com/company/swift-it-ltd-bd/">
                      <FaLinkedinIn />
                    </a>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col lg={3} md={4} sm={12} data-aos="zoom-in">
              <div className="team-member-block">
                <img src={masum} alt="masum" className="client-av" />
                <h3> Masum Hasan</h3>
                <p className="text-center">
                  <strong>Tech Lead</strong>
                </p>

                <div className="team-social m-0 d-flex">
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaFacebookF />
                    </Button>
                  </NavLink>
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaTwitter />
                    </Button>
                  </NavLink>

                  <Button variant="btn border">
                    <a href="https://www.linkedin.com/company/swift-it-ltd-bd/">
                      <FaLinkedinIn />
                    </a>
                  </Button>
                </div>
              </div>
            </Col>

            <Col lg={3} md={4} sm={12} data-aos="zoom-in">
              <div className="team-member-block">
                <img src={mim} alt="mim" className="client-av" />
                <h3> Mahfuzur Rahman</h3>
                <p className="text-center">
                  <strong>Software Engineer</strong>
                </p>

                <div className="team-social d-flex m-0">
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaFacebookF />
                    </Button>
                  </NavLink>
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaTwitter />
                    </Button>
                  </NavLink>

                  <Button variant="btn border">
                    <a href="https://www.linkedin.com/company/swift-it-ltd-bd/">
                      <FaLinkedinIn />
                    </a>
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12} data-aos="zoom-in">
              <div className="team-member-block">
                <img src={nazmul} alt="nazmul" className="client-av" />
                <h3>Nazmul Hassan</h3>
                <p className="text-center">
                  <strong>Software Engineer</strong>
                </p>

                <div className="team-social m-0 d-flex">
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaFacebookF />
                    </Button>
                  </NavLink>
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaTwitter />
                    </Button>
                  </NavLink>

                  <Button variant="btn border">
                    <a href="https://www.linkedin.com/company/swift-it-ltd-bd/">
                      <FaLinkedinIn />
                    </a>
                  </Button>
                </div>
              </div>
            </Col>

            <Col lg={3} md={4} sm={12} data-aos="zoom-in">
              <div className="team-member-block">
                <img src={neephat} alt={neephat} className="client-av" />
                <h3>Neephat Benazir</h3>
                <p className="text-center">
                  <strong>Software Engineer</strong>
                </p>

                <div className="team-social d-flex m-0">
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaFacebookF />
                    </Button>
                  </NavLink>
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaTwitter />
                    </Button>
                  </NavLink>

                  <Button variant="btn border">
                    <a href="https://www.linkedin.com/company/swift-it-ltd-bd/">
                      <FaLinkedinIn />
                    </a>
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12} data-aos="zoom-in">
              <div className="team-member-block">
                <img src={avater} alt="avater" className="client-av" />
                <h3> Md. Shamim Patawary</h3>
                <p className="text-center">
                  <strong>Software Engineer</strong>
                </p>

                <div className="team-social m-0 d-flex">
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaFacebookF />
                    </Button>
                  </NavLink>
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaTwitter />
                    </Button>
                  </NavLink>

                  <Button variant="btn border">
                    <a href="https://www.linkedin.com/company/swift-it-ltd-bd/">
                      <FaLinkedinIn />
                    </a>
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12} data-aos="zoom-in">
              <div className="team-member-block">
                <img src={mujib} alt="mujib" className="client-av" />
                <h3> Mujibur Rahman</h3>
                <p className="text-center">
                  <strong>QA</strong>
                </p>

                <div className="team-social m-0 d-flex">
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaFacebookF />
                    </Button>
                  </NavLink>
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaTwitter />
                    </Button>
                  </NavLink>

                  <Button variant="btn border">
                    <a href="https://www.linkedin.com/company/swift-it-ltd-bd/">
                      <FaLinkedinIn />
                    </a>
                  </Button>
                </div>
              </div>
            </Col>

            <Col lg={3} md={4} sm={12} data-aos="zoom-in">
              <div className="team-member-block">
                <img src={avater} alt="avater" className="client-av" />
                <h3> Sakib Hossain</h3>
                <p className="text-center">
                  <strong>UI/UX Designer</strong>
                </p>

                <div className="team-social d-flex m-0">
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaFacebookF />
                    </Button>
                  </NavLink>
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaTwitter />
                    </Button>
                  </NavLink>

                  <Button variant="btn border">
                    <a href="https://www.linkedin.com/company/swift-it-ltd-bd/">
                      <FaLinkedinIn />
                    </a>
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12} data-aos="zoom-in">
              <div className="team-member-block">
                <img src={avater} alt="avater" className="client-av" />
                <h3>Shamim Ul Haque</h3>
                <p className="text-center">
                  <strong>SQA Engineer</strong>
                </p>

                <div className="team-social m-0 d-flex">
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaFacebookF />
                    </Button>
                  </NavLink>
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaTwitter />
                    </Button>
                  </NavLink>

                  <Button variant="btn border">
                    <a href="https://www.linkedin.com/company/swift-it-ltd-bd/">
                      <FaLinkedinIn />
                    </a>
                  </Button>
                </div>
              </div>
            </Col>

            <Col lg={3} md={4} sm={12} data-aos="zoom-in">
              <div className="team-member-block">
                <img src={avater} alt={avater} className="client-av" />
                <h3>Md. Rokibul Hossain</h3>
                <p className="text-center">
                  <strong>HR Manager</strong>
                </p>

                <div className="team-social d-flex m-0">
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaFacebookF />
                    </Button>
                  </NavLink>
                  <NavLink exact to="">
                    <Button variant="btn border">
                      <FaTwitter />
                    </Button>
                  </NavLink>

                  <Button variant="btn border">
                    <a href="https://www.linkedin.com/company/swift-it-ltd-bd/">
                      <FaLinkedinIn />
                    </a>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default Team;
