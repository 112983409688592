import React from "react";

import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { FaLockOpen } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaWaveSquare } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaSlideshare } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";

import { FaStarOfLife } from "react-icons/fa";
import { FaScribd } from "react-icons/fa";
import { FaFileCode } from "react-icons/fa";
import { FaSearchengin } from "react-icons/fa";
import { FaSolarPanel } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import worka from "../../assets/images/worka.png";
import workb from "../../assets/images/workb.png";
import workc from "../../assets/images/workc.png";
import workd from "../../assets/images/workd.png";
import worke from "../../assets/images/worke.png";
import workf from "../../assets/images/workf.png";
import indu1 from "../../assets/images/indu1.svg";
import indu2 from "../../assets/images/indu2.svg";
import indu3 from "../../assets/images/indu3.svg";
import indu4 from "../../assets/images/indu4.svg";
import indu5 from "../../assets/images/indu5.svg";
import indu6 from "../../assets/images/indu6.svg";

import pwaservices from "../../assets/images/pwa-service-pic.svg";
import whatispwa from "../../assets/images/what-is-pwa.svg";

import pwabenefit1 from "../../assets/images/pwa-benefit-1.svg";
import pwabenefit2 from "../../assets/images/pwa-benefit-2.svg";
import pwabenefit3 from "../../assets/images/pwa-benefit-3.svg";
import pwabenefit4 from "../../assets/images/pwa-benefit-4.svg";
import pwabenefit5 from "../../assets/images/pwa-benefit-5.svg";
import pwabenefit6 from "../../assets/images/pwa-benefit-6.svg";
// import whySwift IT from '../../assets/images/why-Swift IT-for-pwa.svg';
import hirepwa from "../../assets/images/hire-pwa-develoers.svg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Progressivewebapp = () => {
  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>
                    Progressive Web App (PWA) Development Company in Bangladesh{" "}
                  </strong>
                </h1>
                <p className="text-white p20 mb-4">
                  {" "}
                  Swift IT is one of the top progressive web application
                  companies in Bangladesh with expertise in creating reliable
                  and fast PWAs with a combined proficiency of web and mobile
                  technologies. We have a dynamic team of developers who creates
                  custom-built progressive web applications using elite coding
                  practices and innovative web technologies like HTML, CSS,
                  Javascript etc. We help customers explore the power of
                  progressive web applications with faster load time to achieve
                  an enhanced SEO experience{" "}
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaLockOpen />
                    </span>
                    <label> Security & IP Protection </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaGlobe />
                    </span>
                    <label> 5 Years Experience </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaWaveSquare />
                    </span>
                    <label> Flexible Engagement Models </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileAlt />
                    </span>
                    <label> Strict NDA Terms </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileInvoice />
                    </span>
                    <label> No Hidden Costs </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaClock />
                    </span>
                    <label> On-Time Project Delivery </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <div className="get-touch-form">
                <Form>
                  <div className="mb-3 me-0 sch-block">
                    <h3 className="h3-schedule mb-1">
                      {" "}
                      <strong>Lets Discuss Your Project</strong>{" "}
                    </h3>
                    <p className="mb-5">
                      {" "}
                      Guaranteed Response within One Business Day!{" "}
                    </p>
                  </div>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaUserAlt />
                    </span>
                    <Form.Control placeholder="Name" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re" controlId="">
                    {" "}
                    <span>
                      <FaCommentAlt />
                    </span>
                    <Form.Control type="email" placeholder="Email" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaMobileAlt />
                    </span>
                    <Form.Control type="text" placeholder="Contact Number" />
                  </Form.Group>
                  <Form.Group
                    className="mb-4 pos-re"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {" "}
                    <span className="comspan">
                      <FaCommentDots />
                    </span>
                    <Form.Control
                      as="textarea"
                      className="textform"
                      placeholder="Comment"
                      rows={2}
                    />
                  </Form.Group>
                  <Col md={12} className="text-center mt-4">
                    <Button variant="primary btn-custom mt-3 btn-big">
                      Submit
                      <FaArrowRight className="ms-5" />
                    </Button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our PWA Capabilities</h2>
              <p>
                {" "}
                Our skilful and professional team of ASP.net developers creates
                industry-specific applications that can offer you high
                productivity. The agile development process that we follow
                throughout the project cycle helps in the on-time delivery of
                the applications.{" "}
              </p>
              <p>
                {" "}
                It’s an exciting information that a high-quality Progressive Web
                App can bring remarkable benefits, making it easy to please your
                users, grow engagement and increase conversions.  Regardless of
                the browser you use, Progressive Web Apps (PWA) work for every
                user located anywhere in the world. No matter, whether you live
                in India or UK, and use Chrome or Opera, Progressive Web Apps
                will work just as well because they’re built with progressive
                enhancement. Swift IT, being a top progressive web app
                development company in India, creates web apps with highly
                secure and fast-loading web pages. Above all, the PWAs we
                develop ensure flawless performance similar to that of native
                mobile applications.{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature pt-2">
        <Container>
          <Row>
            <Col md={6} className="mb-4" data-aos="fade-right">
              <h2 className="mt-5">
                What Is Progressive Web App
                <br />
                Development?
              </h2>
              <p>
                {" "}
                Progressive Web App is a futurist web app that brings mobile app
                experience without requiring the users to install an application
                from their play store/ app store. As PWAs are built on front-end
                technologies like JavaScript, HTML and CSS, they provide high
                level of performance and usability that’s on par with native
                mobile applications. This progressive application is getting
                immensely popular these days because of its streamlined and fast
                performance. Since the application is responsive, supports
                offline usage as well as push notifications and consume minimal
                data storage space, it is one of the most preferred web apps
                today. It loads faster even on feeble networks, adds an icon on
                the home screen, and gives full-screen experience.{" "}
              </p>
            </Col>
            <Col md={6} className="mt-5 mb-4" data-aos="fade-left">
              <img
                className="abouimg width96 mt-4"
                src={whatispwa}
                alt="whatispwa"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <div className="clearfix"></div>
      <section className="bg-gray bg-deep-gray mt-5 pt-5">
        <Container>
          <h2 className="text-center mb-4">Benefits Of Progressive Web App</h2>
          <Row>
            <Col md={4}>
              <div className="benifit-block">
                {" "}
                <span>
                  {" "}
                  <img
                    className="pwabenefit"
                    src={pwabenefit1}
                    alt="pwabenefit1"
                  />{" "}
                </span>
                <h3>Responsive</h3>
                <p>
                  {" "}
                  Be it a desktop, a tablet or a mobile,   Progressive Web Apps
                  will fit whatever the device is. They can work even offline
                  and on low-quality networks.{" "}
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="benifit-block">
                {" "}
                <span>
                  {" "}
                  <img
                    className="pwabenefit"
                    src={pwabenefit2}
                    alt="pwabenefit2"
                  />{" "}
                </span>
                <h3>Fast And Reliable </h3>
                <p>
                  {" "}
                  The service workers enable Progressive Web Apps to load
                  promptly, irrespective of the network quality. They respond
                  instantly to user interactions without janky scrolling.{" "}
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="benifit-block">
                {" "}
                <span>
                  {" "}
                  <img
                    className="pwabenefit"
                    src={pwabenefit3}
                    alt="pwabenefit3"
                  />{" "}
                </span>
                <h3>Up-To-Date And Safe</h3>
                <p>
                  {" "}
                  The service worker facilitates Progressive Web App to be
                  always up-to-date. As progressive apps are served using HTTPS,
                  no one without authorization can tamper your app.{" "}
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="benifit-block">
                {" "}
                <span>
                  {" "}
                  <img
                    className="pwabenefit"
                    src={pwabenefit4}
                    alt="pwabenefit4"
                  />{" "}
                </span>
                <h3>User Engagement</h3>
                <p>
                  {" "}
                  PWA offers an immersive full-screen experience and the
                  features like Push Notifications make it very much engaging
                  for the users.{" "}
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="benifit-block">
                {" "}
                <span>
                  {" "}
                  <img
                    className="pwabenefit"
                    src={pwabenefit5}
                    alt="pwabenefit5"
                  />{" "}
                </span>
                <h3>Installation </h3>
                <p>
                  {" "}
                  Allows users to add progressive web apps to their home screen
                  without any app store troubles. They can also be shared via
                  URL and do not involve any complex installation process.{" "}
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="benifit-block">
                {" "}
                <span>
                  {" "}
                  <img
                    className="pwabenefit"
                    src={pwabenefit6}
                    alt="pwabenefit6"
                  />{" "}
                </span>
                <h3>No Limitations </h3>
                <p>
                  {" "}
                  Regardless of the device specifications and browser type, PWAs
                  can be downloaded easily by a user.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature pt-2">
        <Container>
          <Row>
            <Col md={6} className="mt-5 mb-4" data-aos="fade-left">
              <img className="abouimg width90 mt-4" src="" alt="whySwift IT" />
            </Col>
            <Col md={6} className="mb-4 mt-5 pt-4" data-aos="fade-right">
              <h2 className="mt-5">
                Why Swift IT For
                <br />
                Progressive Web App Development?
              </h2>
              <p>
                Being one of the most reliable Web and Mobile App Solutions
                providers, we can assure that the Progressive Web App
                development services we offer are helpful to revolutionize the
                user interactions. We help clients build progressive web
                applications that load quickly and work independently of the
                internet connection. By choosing Swift IT for progressive web
                app development services, you can gain a quickly deployable
                product that is highly responsive and delivers app-like user
                experience. PWA experts at Swift IT focus on developing premium
                quality progressive web apps that enable the user to add the app
                to their device and pin it on their home screen. With minimal
                data storage space, we develop PWAs that run on varying screen
                sizes and devices, enhancing the user experience greatly.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature pt-2">
        <Container>
          <Row>
            <Col md={6} className="mb-4 mt-5 pt-4" data-aos="fade-right">
              <h2 className="mt-5">
                Hire Progressive Web App Developers
                <br />
                In India At Swift IT
              </h2>
              <p>
                Swift IT is one of the leading companies in India which provides
                Progressive Web Application Development Services to help the
                clients build fast and reliable apps with competitive
                performance and least effort. Our development team is proficient
                in the latest development techniques, which enable them to
                deliver custom, superior quality apps that give native apps feel
                and compatible with multiple devices of different screen sizes.
                Being the top progressive web app development company in India,
                we offer support at each level of development cycle and delivers
                best-in-class applications to our clients worldwide. If you are
                looking to hire Progressive Web App Developers in India, Swift
                IT will help you adopt the best programmers to ensure quality
                and timely development.
              </p>
            </Col>
            <Col md={6} className="mt-5 mb-4" data-aos="fade-left">
              <img
                className="abouimg width90 mt-5"
                src={hirepwa}
                alt="hirepwa"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our Progressive Web App Development Services</h2>
              <p>
                Swift IT is a prominent progressive web app development company
                in India that offers radical PWA solutions to businesses of all
                sizes and types. Through our high-end progressive web app
                development services, we deliver web applications with the same
                simplicity and feel like native apps across different platforms.
                Being one of the top PWA development companies in India, we make
                sure that our PWA services are high performing and
                user-friendly. Our progressive web app development services in
                India include:
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaStarOfLife />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Migration And Upgradation</h5>
                    <p className="mb-0">
                      With the help of our proficient PWA developers, we can
                      migrate your existing application to PWA as per your
                      requirements. We will follow a structured approach for
                      migrating your application without impacting data
                      security. We can help you upgrade your present PWA to its
                      latest version so that the users will get a better
                      experience. We will provide high-end migration services to
                      convert your current apps to PWAs for offline access and
                      better usability.
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Responsive Web App Development</h5>
                    <p className="mb-0">
                      {" "}
                      Through our responsive app development services, we create
                      faster PWAs with smooth interactivity across browsers for
                      numerous devices. Our knowledge can help you build
                      well-designed websites that look attractive even on
                      smaller devices. We are able to build websites that have a
                      higher chance of being discovered by search engines and
                      achieve better Google rankings. Utilize our expertise to
                      create a custom website that meets your company's demands
                      while also appealing to your target audience.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSlideshare />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={pwaservices} alt="pwaservices" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaScribd />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">PWA Design</h5>
                    <p className="mb-0 pb-3">
                      To enhance user engagement, our design team designs
                      responsive web interfaces for progressive apps. Swift IT
                      PWA development company in India will create high-fidelity
                      user interface models, wireframes, and prototypes that are
                      consistent with your brand image. We will follow a
                      flawless and consistent development approach to offer you
                      great app design and an optimized user experience. Our
                      skilled designers craft elegant and engaging user
                      interfaces to deliver the users with the most required
                      functionality at their fingertips. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">QA And Testing For PWA Apps </h5>
                    <p className="mb-0">
                      {" "}
                      We will provide comprehensive QA and testing services to
                      make sure that your PWA solutions are glitch-free. We
                      utilize the best and latest testing techniques to deliver
                      high-performance software that exactly matches your
                      expectations. We leverage both manual and software-based
                      testing to check whether the applications can run on
                      different browsers and devices. Our quality assurance
                      engineers have extensive experience in performing
                      end-to-end system analysis so they can detect and resolve
                      software issues efficiently and ensure consistent
                      application performance.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Apps With Application Shell Model </h5>
                    <p className="mb-0 pb-4">
                      {" "}
                      Our skilled developers build progressive web apps with the
                      most up-to-date app shell models, ensuring a better user
                      experience without compromising speed. We have a pool of
                      skilled developers to build websites that feels and
                      performs like a native app, with real-time interactivity
                      and solid performance while retaining all of the web's
                      advantages. We can assist you in creating high-performance
                      PWA based on app shell architecture that is simple to
                      navigate without causing any hindrance. We can build
                      applications that permit you to obtain significant pixels
                      on the screen without using the network, even though your
                      content is ultimately delivered through it. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Custom PWA Development</h5>
                    <p className="mb-0">
                      {" "}
                      As a leading PWA development company in India, we merge
                      the web and mobile worlds to create powerful progressive
                      web apps that perform dependably in every situation,
                      including a poor internet connection. We craft
                      high-quality PWAs with the goal of increasing user
                      engagement and conversions. We will employ the appropriate
                      tools and techniques to provide you with superior PWA
                      solutions. We develop cross-platform PWAs with unique
                      features that deliver smooth user experiences similar to
                      those found in native apps. Our progressive web app
                      developers have years of expertise in creating dynamic web
                      apps that boost productivity and income.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSolarPanel />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </section>

      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel
              className="owl-theme slider-custom"
              {...options2}
              loop
              margin={20}
              nav
            >
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worka}
                    alt="worka"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workb}
                    alt="workb"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workc}
                    alt="workc"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workd}
                    alt="workd"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worke}
                    alt="worke"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workf}
                    alt="workf"
                  />{" "}
                </div>
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
              <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu1} alt="indu1" />{" "}
                </div>
                <h5 className="text-center mt-5">Ecommerce</h5>
                <p>
                  {" "}
                  The e-commerce solutions offered at Swift IT are on-the-go and
                  assured to bring benefits with increased ROI and overall
                  productivity. We provide custom e-commerce development
                  services and develop web apps for any kind of business across
                  various industries and enterprises ranging from B2B and B2C
                  trading networks to small, middle-sized, and large businesses.
                  These custom solutions meet the user’s requirements by
                  applying specific areas of specialization.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu2} alt="indu2" />{" "}
                </div>
                <h5 className="text-center mt-5">Healthcare</h5>
                <p>
                  {" "}
                  At Swift IT, we help you develop healthcare software
                  applications that automate processes such as patient
                  management, lab management, test result analysis, and data
                  collection. For the medical technology companies, from
                  consulting to maintenance and support we always lent our
                  supporting hands to tackle your technological dilemmas.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu3} alt="indu3" />{" "}
                </div>
                <h5 className="text-center mt-5">Logistics</h5>
                <p>
                  The experts at Swift IT help you build innovative and
                  impressive tech-enabled logistics software applications to
                  streamline the supply chain and provide flawless customer
                  support. The warehouse management software also makes sure to
                  enhance warehouse efficiency. The expert logistics and
                  transportation management software offered by Swift IT thereby
                  help you do your tasks more effectively.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu4} alt="indu4" />{" "}
                </div>
                <h5 className="text-center mt-5">Education</h5>
                <p>
                  {" "}
                  In the education field, Swift IT is engaged in integrated
                  learning system software development, virtual learning
                  management system, LMS software development, and even
                  personalized e-learning software systems. Interactive,
                  convenient, and cost-effective solutions are what we offer at
                  Swift IT.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu5} alt="indu5" />{" "}
                </div>
                <h5 className="text-center mt-5">Banking</h5>
                <p>
                  {" "}
                  From financial software development, custom banking software
                  development, digital banking software services, and custom
                  Fintech development, we at Swift IT extend our software
                  development experience in the industry of banking and finance
                  as well. With custom features, and UI/UX designs we ensure the
                  highest standards and security to the software developed.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu6} alt="indu6" />{" "}
                </div>
                <h5 className="text-center mt-5">Manufacturing</h5>
                <p>
                  {" "}
                  Implement manufacturing software for ensuring quality
                  management and maintenance software including the application
                  of intelligent products that help in accelerating the existing
                  processes and bringing down operational costs.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default Progressivewebapp;
