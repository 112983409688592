// import Container from 'react-bootstrap/Container';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import Common from "../../layouts/Common";
// import Container from 'react-bootstrap/Container';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import {
  FaArrowRight,
  FaCheckCircle,
  FaClock,
  FaCommentAlt,
  FaCommentDots,
  FaFileAlt,
  FaFileCode,
  FaFileInvoice,
  FaGlobe,
  FaLaptop,
  FaLockOpen,
  FaMobileAlt,
  FaScrewdriver,
  FaSearchengin,
  FaServer,
  FaSolarPanel,
  FaSoundcloud,
  FaUserAlt,
  FaWaveSquare,
} from "react-icons/fa";
import about from "../../assets/images/about.svg";
import indcom from "../../assets/images/indcom.svg";
import serviceall from "../../assets/images/serviceall.svg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContactForm from "../../components/ContactForm";
import IndustryExperience from "../../components/IndustryExperience";
import RecentWork from "../../components/RecentWork";

const SoftwareOutsourcing = () => {
  const iconList = [
    FaArrowRight,
    FaCheckCircle,
    FaClock,
    FaCommentAlt,
    FaCommentDots,
    FaFileAlt,
    FaFileCode,
    FaFileInvoice,
    FaGlobe,
    FaLaptop,
    FaLockOpen,
    FaMobileAlt,
    FaScrewdriver,
    FaSearchengin,
    FaServer,
    FaSolarPanel,
    FaSoundcloud,
    FaUserAlt,
    FaWaveSquare,
  ];
  const iconList2 = [
    "FaArrowRight",
    "FaCheckCircle",
    "FaClock",
    "FaCommentAlt",
    "FaCommentDots",
    "FaFileAlt",
    "FaFileCode",
    "FaFileInvoice",
    "FaGlobe",
    "FaLaptop",
    "FaLockOpen",
    "FaMobileAlt",
    "FaScrewdriver",
    "FaSearchengin",
    "FaServer",
    "FaSolarPanel",
    "FaSoundcloud",
    "FaUserAlt",
    "FaWaveSquare",
  ];

  const serviceData = {
    title: "Software Outsourcing Company in Bangladesh",
    subTitle:
      "Swift IT Solutions is the perfect answer to all kinds of offshore development needs. Having an expert base that specializes in all the popular technologies and has a tendency to learn and grow exponentially in the light of new advancements; we are one reliable software outsourcing company in Bangladesh. From offshore talent hiring in management and development to Mobile App building; Front End Development to Core web development – we are robust, reliable, and uphold work ethic.",
    feature: [
      {
        title: "SECURITY & IP PROTECTION",
        icon: "FaLockOpen",
      },
      {
        title: "5 YEARS EXPERIENCE",
        icon: "FaGlobe",
      },
      {
        title: "FLEXIBLE ENGAGEMENT MODELS",
        icon: "FaWaveSquare",
      },
      {
        title: "STRICT NDA TERMS",
        icon: "FaFileAlt",
      },
      {
        title: "NO HIDDEN COSTS",
        icon: "FaFileInvoice",
      },
      {
        title: "ON-TIME PROJECT DELIVERY",
        icon: "FaClock",
      },
    ],
    heading_one: {
      title: "Why Should You Consider Software Outsourcing?",
      details:
        "Software Outsourcing is the need of the hour. In the pursuit of making cost-efficient software at a swift rate, many companies rely on the trainees and interns. Hiring a specific human resource for a single project that you might or might not get in the future seems like drainage on your resources. Also, if you know that you can get a module developed at a lower price in another country without the loss of time and quality; it is not a wise decision to refrain from that. Software outsourcing is a much better and smart option for companies trying to cut down cost and focus on core business functions. By doing so you can streamline your business process, and the resources at hand can be put to much higher levels of functioning. Outsourcing is one amazing thing that can help accomplish much more with fewer resources.",
      image: "https://www.image.com",
    },
    heading_two: {
      title: "Advantages of outsourcing",
      details: "",
      feature_point: [
        {
          title:
            "Reduced Expenses : Outsourcing allows for smart usage of all kinds of resources, namely – money, time, human resources and infrastructure as well. The company doesn’t have to hire a new employee or buy new resources for him or her to complete the job. When you outsource, the talent can be hired for a single project, multiple projects, a single module, and even a single functionality. The payment is made only for the task done. There are many countries with a big difference in currency value as well. Outsourcing from such countries also allows for cost-cutting on software development.",
          icon: "FaCheckCircle",
        },
        {
          title:
            "Exposure to new talent : Outsourcing enables you to have access to a pool of qualified talents who work efficiently within the given time period. You might find a person or a company that you will enjoy working for a long time down the future. Instead of dealing with the incompetence issues at work, you can work with expert people at some other place who always aim for the success of both the parties.",
          icon: "FaCheckCircle",
        },
        {
          title:
            "Time Savings : A dedicated team of professionals with experience and expertise in a software development company will ensure the delivery of high-quality work on time.",
          icon: "FaCheckCircle",
        },
        {
          title:
            "Flexible Working : Software outsourcing calls for a flexible work-life. There is no need to have a full-fledged team of developers, programmers, and hardware support for all of them. Instead, you can have a very dynamic and consistently motivated virtual team that is spread globally and works independently. Hence, Software Outsourcing with Swift IT is the best thing that you can start to have better work and better ROI.",
          icon: "FaCheckCircle",
        },
      ],
      image: "https://www.image.com",
    },
    service_items: {
      title: "Why Swift IT As Your Software Outsourcing Company In Bangladesh:",
      subTitle:
        "As a leading software outsourcing company in Bangladesh, our team has a 5-step action plan to make outsourcing easier and better for the client while keeping the work quality in mind at all times. The 5-step action plan is as follows:",
      image: "https://www.image.com",
      items: [
        {
          title: "Creative Ideas",
          details:
            "We always promote creativity. There are endless ways to do any task and you have only one way until another one appears or is shown to you. This is why instead of sticking to a single solution for developing projects, we always promote creative solution crafting. With an energetic team of brilliant minds that create solutions instead of following the age-old practices of the IT domain, we have catered to a wide range of products and services.",
        },
        {
          title: "Innovative Solutions",
          details:
            "Business scenarios are ever evolving and the need to have innovative solutions for business is on the rise more than ever. From payment gateways to websites and apps to plug-ins – every single thing is getting smarter and better. Thus, to keep you well-conforming to this race of advancement, we keep on working to deliver innovative solutions that never fail to amaze and achieve more than the desired results.",
        },
        {
          title: "Proper Customization",
          details:
            "We have developed a culture and work ethic in which we work as per the wants of the customer and make sure to make it as good as it can be. Instead of offering the customers what we like and what we can make, we work towards achieving the perfect tangible and intangible translations of customers’ requirements. The effort is put into making good things better; and not ‘our’ versions of customers’ requirements.",
        },
        {
          title: "Technical Expertise",
          details:
            "We have a highly resourceful, competitive, reliable and robust team with a high learning curve. This is why we can cater to almost all the domains of the IT world without any doubt. We never stop learning and acquiring new skills. We never stop practicing our craft so as to achieve the best end results. Learning and knowledge acquiring is a life-long process and we make sure to do it in the best possible way.",
        },
        {
          title: "Cost-Effective Action Plan",
          details:
            "A good developer makes the best products and services for the clients. And, the best developer makes the best products and services with the best possible and the most efficient usage of the resources. This statement drives every single project development at Swift IT.",
        },
      ],
    },
  };

  const renderFeature = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <Col lg={6} sm={12} key={index}>
        <div className="technology-list">
          {" "}
          <span>
            <Icon2 />
          </span>
          <label> {data.title} </label>
        </div>
      </Col>
    );
  };

  const renderlist = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <li key={index}>
        {" "}
        <span>
          <Icon2 />
        </span>
        <p>{data.title}</p>
      </li>
    );
  };

  const renderServiceLeft = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <div className="company-slide mission-vission" key={index}>
        <Row>
          <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-left">
              <Icon2 />
            </div>
          </Col>
          <Col md={9} className="ps-0">
            <h5 className="p-0">{data.title}</h5>
            <p className="mb-0"> {data.details} </p>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>{serviceData.title}</strong>
                </h1>
                <p className="text-white p20 mb-4">{serviceData.subTitle}</p>
              </div>
              <Row>
                {serviceData.feature.map((data) => renderFeature(data))}
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="width-full mt-5" src={indcom} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>{serviceData?.heading_one?.title}</h2>
              <p>{serviceData?.heading_one?.details}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>{serviceData?.heading_two?.title}</h2>
              <p>
                {" "}
                <strong>{serviceData?.heading_two?.details}</strong>{" "}
              </p>
              <ul className="list-inline list-group ul-list-about mt-4">
                {serviceData.heading_two?.feature_point.map((data) =>
                  renderlist(data)
                )}
              </ul>
            </Col>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="abouimg mt-5" src={about} alt="about" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>{serviceData.service_items.title}</h2>
              <p> {serviceData.service_items.subTitle} </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[0].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[0].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[1].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[1].details}{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaMobileAlt />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[2].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[2].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[3].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[3].details}{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[4].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[4].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            {serviceData.service_items?.items[5] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[5]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[5]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaSolarPanel />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            {serviceData.service_items?.items[6] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[6]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[6]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaScrewdriver />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            {serviceData.service_items?.items[7] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[7]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[7]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaSoundcloud />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      <RecentWork />
      <IndustryExperience />
    </Common>
  );
};

export default SoftwareOutsourcing;
