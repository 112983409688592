// import Container from 'react-bootstrap/Container';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import Common from "../../layouts/Common";
// import Container from 'react-bootstrap/Container';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import {
  FaArrowRight,
  FaCheckCircle,
  FaClock,
  FaCommentAlt,
  FaCommentDots,
  FaFileAlt,
  FaFileCode,
  FaFileInvoice,
  FaGlobe,
  FaLaptop,
  FaLockOpen,
  FaMobileAlt,
  FaScrewdriver,
  FaSearchengin,
  FaServer,
  FaSolarPanel,
  FaSoundcloud,
  FaUserAlt,
  FaWaveSquare,
} from "react-icons/fa";
import indcom from "../../assets/images/indcom.svg";
import serviceall from "../../assets/images/serviceall.svg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ContactForm from "../../components/ContactForm";
import IndustryExperience from "../../components/IndustryExperience";
import RecentWork from "../../components/RecentWork";

const NodeJs = () => {
  const iconList = [
    FaArrowRight,
    FaCheckCircle,
    FaClock,
    FaCommentAlt,
    FaCommentDots,
    FaFileAlt,
    FaFileCode,
    FaFileInvoice,
    FaGlobe,
    FaLaptop,
    FaLockOpen,
    FaMobileAlt,
    FaScrewdriver,
    FaSearchengin,
    FaServer,
    FaSolarPanel,
    FaSoundcloud,
    FaUserAlt,
    FaWaveSquare,
  ];
  const iconList2 = [
    "FaArrowRight",
    "FaCheckCircle",
    "FaClock",
    "FaCommentAlt",
    "FaCommentDots",
    "FaFileAlt",
    "FaFileCode",
    "FaFileInvoice",
    "FaGlobe",
    "FaLaptop",
    "FaLockOpen",
    "FaMobileAlt",
    "FaScrewdriver",
    "FaSearchengin",
    "FaServer",
    "FaSolarPanel",
    "FaSoundcloud",
    "FaUserAlt",
    "FaWaveSquare",
  ];

  const serviceData = {
    title: "Node.JS Development Company in Bangladesh",
    subTitle:
      "Swift IT is one of the most trusted companies for Node.js development in Bangladesh. We create design-friendly and efficient applications using the asynchronous javascript runtime environment of Node.js. Our team develops custom-made, budget-friendly and scalable applications using Node.js for web and mobile.",
    feature: [
      {
        title: "SECURITY & IP PROTECTION",
        icon: "FaLockOpen",
      },
      {
        title: "1 YEARS EXPERIENCE",
        icon: "FaGlobe",
      },
      {
        title: "FLEXIBLE ENGAGEMENT MODELS",
        icon: "FaWaveSquare",
      },
      {
        title: "STRICT NDA TERMS",
        icon: "FaFileAlt",
      },
      {
        title: "NO HIDDEN COSTS",
        icon: "FaFileInvoice",
      },
      {
        title: "ON-TIME PROJECT DELIVERY",
        icon: "FaClock",
      },
    ],
    heading_one: {
      title: "Our Node.js Capabilities",
      details:
        "Our skilful and professional team of Node.js developers creates industry-specific applications that can offer you high productivity. The agile development process that we follow throughout the project cycle helps in the on-time delivery of the applications. Looking for developing your project on a reliable software platform called Node.js? Yes you are opting for stretching performance and end to end reliability. Swift IT Solutions is the most preferred Node.js development company in Bangladesh with years of experience in this field. Node.js uses the V8 javascript engine developed by Google chrome. It has the capability to bear an increasing amount of workload, in a sustainable method. The V8 engine is exceptionally fast. If your application does a lot of activity on the internet or the filesystem, Nodejs is the fastest technology out there to develop your application. Due to these reasons, Node.JS is the preferred platform for many software outsourcing companies to develop large database-related applications.",
      image: "https://www.image.com",
    },
    heading_two: {
      title: "Advantages of outsourcing",
      details: "",
      feature_point: [
        {
          title: "",
          icon: "FaCheckCircle",
        },
      ],
      image: "https://www.image.com",
    },
    service_items: {
      title: "Our NodeJS Services",
      subTitle:
        "Get advanced applications and solutions from Swift IT, one of the leading Node JS development companies in Bangladesh. Swift IT Node Js development services in Bangladesh help you to meet the web app development needs of your business easily. Our tailor-made Node JS development services is a combination of modern frameworks, advanced tools, and technology expertise. Here are some of the exclusive services that you get when you choose Swift IT as your Node JS development company in Bangladesh.",
      image: "https://www.image.com",
      items: [
        {
          title: "Express JS Development",
          details:
            "Our developers use Express JS along with node JS for building front-end as well as back end. Our professional Express.js development services will ensure the creation of best websites and mobile applications with easy maintenance. Moreover, we develop websites with Express JS module wise for maintaining the chain of analysis, design, coding and testing the apps.",
        },
        {
          title: "Consulting Service",
          details:
            "Swift IT has a professional team of engineers and project managers to assist you with complete NodeJS consulting services. Our knowledgeable tech specialists understand your business and can provide you with the proper advice to help you achieve your goals. Our consulting services assist you in establishing future-proof applications and optimizing your operations. Our NodeJS professionals can deliver 24/7 support to provide clients with the greatest consultation, allowing us to become Bangladesh's most desired NodeJS development company.",
        },
        {
          title: "Plugin Development",
          details:
            "We are experts in developing custom NodeJS plugins that can improve the performance of online applications. Our Node.js plugin development service is very popular since we have provided bespoke plugins that cater to the needs of businesses of all sizes and types. We have extensive experience in developing and integrating custom plugins. Utilize our expertise in NodeJS plugin development to boost the capabilities and functionalities of your applications.",
        },
        {
          title: "API Development",
          details:
            "Swift IT is Bangladesh's best NodeJS development organization specialising in NodeJS API development and integration. We work skillfully to deliver enterprise-oriented API development services that are high-performing and scalable while staying within your budget. We can help you develop ready-for-use and secure REST APIs that can efficiently integrate your applications with other systems. We are able to fulfil all your API-related requirements and offer powerful API-based applications that are developed right from scratch using Express js.",
        },
        {
          title: "Web App Development",
          details:
            "Our developers have extensive knowledge and experience in providing web development services tailored to the needs of our clients. Our highly competent Node.js application developers create powerful, scalable, and custom NodeJS applications that run smoothly across all devices. By leveraging open two-way communication channels in NodeJS, we are able to build real-time web applications that can perform seamlessly. We can offer you the top NodeJS development services for the most cost-effective customer budget, and we do so in a timely manner.",
        },
        {
          title: "Mobile App Development",
          details:
            "We provide high-class Node.js development services to help businesses create custom mobile apps. We follow the best practices and implement out-of-the-box strategies to build high-performance, flexible, and lightweight mobile apps that can streamline your business processes. Since NodeJS can handle the heavy traffic of users and data, we are able to develop user-centric apps that satisfy your custom features and functionalities without compromising performance. Our Node.js specialists create enterprise-grade NodeJS solutions for small and mid-sized businesses and reduce the cost of expansion.",
        },
        {
          title: "Support And Maintenance",
          details:
            "Apart from the Node.Js development services, Swift IT as a leading Node JS development company in Bangladesh provides outstanding support and maintenance services to enhance the functionality of your existing system and ensure a seamless user experience. We can aid you in streamlining your applications by modifying them effectively while maintaining their stability and functionality. We will evaluate your current application and implement standardized methodologies to improve the performance of your app and maintain it bug-free for a long time. We deliver world-class NodeJS maintenance services to clients all over the world and assist them in resolving logical and technical challenges with software applications.",
        },
        {
          title: "Migration And Upgradation",
          details:
            "Migrating an existing application to NodeJS is an excellent technique to handle a high number of concurrent connections in a non-blocking manner. As a qualified Node JS development firm in Bangladesh, we have specialists who offer cost-effective, hassle-free migration services. We are adept at upgrading your current NodeJS App to the most recent version. We will help you move your application's data, set it up, and configure it, as well as make sure all of the features are working. You can smoothly migrate your legacy app to NodeJS with the support of our talented developers.",
        },
      ],
    },
  };

  const renderFeature = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <Col lg={6} sm={12} key={index}>
        <div className="technology-list">
          {" "}
          <span>
            <Icon2 />
          </span>
          <label> {data.title} </label>
        </div>
      </Col>
    );
  };

  const renderlist = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <li key={index}>
        {" "}
        <span>
          <Icon2 />
        </span>
        <p>{data.title}</p>
      </li>
    );
  };

  const renderServiceLeft = (data, index) => {
    const Icon = iconList2.findIndex((element) => element == data.icon);
    const Icon2 = iconList[Icon ? Icon : 0];
    return (
      <div className="company-slide mission-vission" key={index}>
        <Row>
          <Col md={3}>
            <div className="slider-icon icon-all" data-aos="flip-left">
              <Icon2 />
            </div>
          </Col>
          <Col md={9} className="ps-0">
            <h5 className="p-0">{data.title}</h5>
            <p className="mb-0"> {data.details} </p>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>{serviceData.title}</strong>
                </h1>
                <p className="text-white p20 mb-4">{serviceData.subTitle}</p>
              </div>
              <Row>
                {serviceData.feature.map((data) => renderFeature(data))}
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="width-full mt-5" src={indcom} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>{serviceData?.heading_one?.title}</h2>
              <p>{serviceData?.heading_one?.details}</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>{serviceData.service_items.title}</h2>
              <p> {serviceData.service_items.subTitle} </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[0].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[0].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[1].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[1].details}{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaMobileAlt />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[2].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[2].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[3].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[3].details}{" "}
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">
                      {serviceData.service_items?.items[4].title}
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {serviceData.service_items?.items[4].details}{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            {serviceData.service_items?.items[5] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[5]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[5]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaSolarPanel />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            {serviceData.service_items?.items[6] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[6]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[6]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaScrewdriver />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
            {serviceData.service_items?.items[7] && (
              <Col md={6}>
                <div className="company-slide mission-vission">
                  <Row>
                    <Col md={9} className="pe-0 text-end">
                      <h5 className="p-0">
                        {serviceData.service_items?.items[7]?.title}
                      </h5>
                      <p className="mb-0">
                        {" "}
                        {serviceData.service_items?.items[7]?.details}{" "}
                      </p>
                    </Col>
                    <Col md={3}>
                      <div
                        className="slider-icon icon-all"
                        data-aos="flip-right"
                      >
                        <FaSoundcloud />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      <RecentWork />
      <IndustryExperience />
    </Common>
  );
};

export default NodeJs;
