import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Button from "react-bootstrap/Button";
import "./Footer.css";
import logo2 from "../assets/images/Swift-IT.png";
import { NavLink, useNavigate } from "react-router-dom";
import { FaPeriscope } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import getques from "../assets/images/getques.svg";
import bd from "../assets/images/bd.svg";
import usa from "../assets/images/usa.webp";
import FreeQuote from "../pages/FreeQuote";
import Modal from "react-bootstrap/Modal";
import BackToTop from "react-back-to-top-button";
import swiftFooter from "../assets/images/swift.png";
const Footer = ({ onGotoSection }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  return (
    <div className="footer-area pt-0">
      <section className="all-company-feature mt-2 mb-150">
        <Container data-aos="fade-up" data-aos-duration="3000">
          <Row>
            <Col md={12} className="text-center">
              <img src={getques} alt="clientimg2" className="get-quote" />
            </Col>
          </Row>
        </Container>
        <div className="bg-get-quote">
          <Container>
            <Row>
              <Col md={8}>
                <h6>GET IN TOUCH</h6>
                <h3>
                  What sats,
                  <br />
                  We Get Down To Business?
                </h3>
              </Col>
              <Col md={4} className="text-end">
                <Button
                  variant="primary btn-custom rounded border-1 mt-3 btn-big border-white w260"
                  onClick={handleShow}
                >
                  Get a Free Quote
                  <FaArrowRight className="ms-5" />
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body>
          <FreeQuote />
        </Modal.Body>
      </Modal>

      <Container>
        <Row className="justify-content-between">
          <Col md={3}>
            <h3>
              <NavLink className="logohideone" exact to="/home">
                <img src={swiftFooter} alt="logo2" className="footerlogo" />
              </NavLink>
            </h3>
            <p className="text-gray mt-3 jus-text">
              {" "}
              Swift IT Ltd. is a leading software development company with
              office in Bangladesh. Our deep understanding about the software
              outsourcing makes us a unique solution provider.
            </p>
          </Col>
          <Col md={3} className="link-list company-list-link">
            <h3>Services</h3>
            <ul className="list-group list-inline">
              <li>
                <NavLink exact="true" to="/service" onClick={onGotoSection}>
                  ERP Software Development
                </NavLink>
              </li>
              <li>
                <NavLink exact="true" to="/service" onClick={onGotoSection}>
                  CRM Software Development
                </NavLink>
              </li>
              <li>
                <NavLink exact="true" to="/service" onClick={onGotoSection}>
                  Website Design & Development
                </NavLink>
              </li>
              <li>
                <NavLink exact="true" to="/service" onClick={onGotoSection}>
                  Digital Marketing Solution
                </NavLink>
              </li>
              <li>
                <NavLink exact="true" to="/service" onClick={onGotoSection}>
                  Hardware Supply Service
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/service" onClick={onGotoSection}>
                  Networking Infrastructure Solution
                </NavLink>
              </li>
            </ul>
          </Col>
          {/* <Col md={3}>
            <h3>
              {" "}
              <img src={usa} alt="usa" className="flag" />
              United States
            </h3>
            <ul className="list-group list-inline company-list-link">
              <li>
                {" "}
                <span>
                  <FaPeriscope />
                </span>
                <label>
                  {" "}
                  28-07 Jackson Ave, Long Island City, NY, 11101, USA{" "}
                </label>
              </li>
              <li>
                {" "}
                <span>
                  <FaMobileAlt />
                </span>
                <label> (332) 203-6066</label>
              </li>
            </ul>
          </Col> */}

          <Col md={3} className="text-start">
            <h3>Our Office</h3>
            <ul className="list-group list-inline company-list-link">
              <li>
                {" "}
                <span>
                  <FaPeriscope />
                </span>
                <label>
                  {" "}
                  4th floor, House No-2/1 , Block-A, Lalmatia, <br />
                  Dhaka-1205{" "}
                </label>
              </li>
              <li>
                {" "}
                <span>
                  <FaMobileAlt />
                </span>
                <label> +880 1770-772626 </label>
              </li>
            </ul>
          </Col>
        </Row>
        <hr className="hr mt-3 mb-3" />
        <Row className="footer-bottom">
          <Col md={6}>
            <p>
              {" "}
              © {new Date().getFullYear()} All Rights Reserved By SwiftIT Ltd{" "}
            </p>
          </Col>
          <Col md={6}>
            {/* <ul className="list-inline">
        <li>
          <NavLink exact to="">Privacy & Policy</NavLink>
        </li>
        <li>
          <NavLink exact to="">FAQs</NavLink>
        </li>
        <li>
          <NavLink exact to="">Terms</NavLink>
        </li>
      </ul>  */}
            <div className="social-icon">
              {/* <NavLink exact to="">
                <Button variant="btn">
                  <FaFacebookF />
                </Button>
              </NavLink>
              <NavLink exact to="">
                <Button variant="btn">
                  <FaTwitter />
                </Button>
              </NavLink> */}
              <Button variant="btn border-dark ">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/swiftitltd"
                  className="text-dark"
                >
                  <FaFacebookF />
                </a>
              </Button>
              <Button variant="btn border-dark ">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/swift-it-ltd-bd/"
                  className="text-dark"
                >
                  <FaLinkedinIn />
                </a>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <BackToTop
        showOnScrollDown
        showAt={1200}
        speed={1500}
        easing="easeInOutQuint"
      >
        <button className="back-to-top">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
          </svg>
        </button>
      </BackToTop>
    </div>
  );
};

export default Footer;
