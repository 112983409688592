import React from "react";
// import Container from 'react-bootstrap/Container';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import indu1 from "../assets/images/indu1.svg";
import indu2 from "../assets/images/indu2.svg";
import indu3 from "../assets/images/indu3.svg";
import indu4 from "../assets/images/indu4.svg";
import indu5 from "../assets/images/indu5.svg";
import indu6 from "../assets/images/indu6.svg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const IndustryExperience = () => {
  return (
    <section className="all-company-feature">
      <Container>
        <Row>
          <Col md={12} className="text-center mt-5 pt-5">
            <h2>Our Industry Experience</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div
                className="indus-img"
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img src={indu1} alt="indu1" />{" "}
              </div>
              <h5 className="text-center mt-5">Ecommerce</h5>
              <p>
                {" "}
                The e-commerce solutions offered at Swift IT are on-the-go and
                assured to bring benefits with increased ROI and overall
                productivity. We provide custom e-commerce development services
                and develop web apps for any kind of business across various
                industries and enterprises ranging from B2B and B2C trading
                networks to small, middle-sized, and large businesses. These
                custom solutions meet the user’s requirements by applying
                specific areas of specialization.{" "}
              </p>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div
                className="indus-img"
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img src={indu2} alt="indu2" />{" "}
              </div>
              <h5 className="text-center mt-5">Healthcare</h5>
              <p>
                {" "}
                At Swift IT, we help you develop healthcare software
                applications that automate processes such as patient management,
                lab management, test result analysis, and data collection. For
                the medical technology companies, from consulting to maintenance
                and support we always lent our supporting hands to tackle your
                technological dilemmas.{" "}
              </p>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div
                className="indus-img"
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img src={indu3} alt="indu3" />{" "}
              </div>
              <h5 className="text-center mt-5">Logistics</h5>
              <p>
                The experts at Swift IT help you build innovative and impressive
                tech-enabled logistics software applications to streamline the
                supply chain and provide flawless customer support. The
                warehouse management software also makes sure to enhance
                warehouse efficiency. The expert logistics and transportation
                management software offered by Swift IT thereby help you do your
                tasks more effectively.
              </p>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div
                className="indus-img"
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img src={indu4} alt="indu4" />{" "}
              </div>
              <h5 className="text-center mt-5">Education</h5>
              <p>
                {" "}
                In the education field, Swift IT is engaged in integrated
                learning system software development, virtual learning
                management system, LMS software development, and even
                personalized e-learning software systems. Interactive,
                convenient, and cost-effective solutions are what we offer at
                Swift IT.{" "}
              </p>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div
                className="indus-img"
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img src={indu5} alt="indu5" />{" "}
              </div>
              <h5 className="text-center mt-5">Banking</h5>
              <p>
                {" "}
                From financial software development, custom banking software
                development, digital banking software services, and custom
                Fintech development, we at Swift IT extend our software
                development experience in the industry of banking and finance as
                well. With custom features, and UI/UX designs we ensure the
                highest standards and security to the software developed.{" "}
              </p>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className="company-feature industry-feature">
              <div
                className="indus-img"
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <img src={indu6} alt="indu6" />{" "}
              </div>
              <h5 className="text-center mt-5">Manufacturing</h5>
              <p>
                {" "}
                Implement manufacturing software for ensuring quality management
                and maintenance software including the application of
                intelligent products that help in accelerating the existing
                processes and bringing down operational costs.{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IndustryExperience;
