import React from "react";
import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { FaLockOpen } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaWaveSquare } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaLaptop } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";
import { FaSoundcloud } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { FaServer } from "react-icons/fa";
import { FaSearchengin } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import worka from "../../assets/images/worka.png";
import workb from "../../assets/images/workb.png";
import workc from "../../assets/images/workc.png";
import workd from "../../assets/images/workd.png";
import worke from "../../assets/images/worke.png";
import workf from "../../assets/images/workf.png";
import indu1 from "../../assets/images/indu1.svg";
import indu2 from "../../assets/images/indu2.svg";
import indu3 from "../../assets/images/indu3.svg";
import indu4 from "../../assets/images/indu4.svg";
import indu5 from "../../assets/images/indu5.svg";
import indu6 from "../../assets/images/indu6.svg";
import about from "../../assets/images/about.svg";
import aws from "../../assets/images/aws-pic-2.png";
import awsservice from "../../assets/images/aws-service-pic.png";
import awspic1 from "../../assets/images/aws-pic-1.png";
import toolstech from "../../assets/images/aws-tech-bg.png";

import serviceall from "../../assets/images/serviceall.svg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Amazonweb = () => {
  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>
                    AWS Development Company
                    <br />
                    in Bangladesh{" "}
                  </strong>
                </h1>
                <p className="text-white p20 mb-4">
                  With more than ten plus years of experience in developing web
                  applications, we are the one-stop-shop for building, deploying
                  and running your mission-critical web applications on AWS,
                  allowing continuous deployment, reducing the need for
                  third-party solutions and faster shipping. Throughout these
                  years, we have delivered our web development services to many
                  enterprises and have developed solid relations that brought
                  promising results. By choosing our AWS app development
                  services, you can eliminate infrastructure costs, improve
                  operational resilience, and business agility due to faster
                  deployment of new features, with fewer errors.{" "}
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaLockOpen />
                    </span>
                    <label> Security & IP Protection </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaGlobe />
                    </span>
                    <label> 5 Years Experience </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaWaveSquare />
                    </span>
                    <label> Flexible Engagement Models </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileAlt />
                    </span>
                    <label> Strict NDA Terms </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileInvoice />
                    </span>
                    <label> No Hidden Costs </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaClock />
                    </span>
                    <label> On-Time Project Delivery </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <div className="get-touch-form">
                <Form>
                  <div className="mb-3 me-0 sch-block">
                    <h3 className="h3-schedule mb-1">
                      {" "}
                      <strong>Lets Discuss Your Project</strong>{" "}
                    </h3>
                    <p className="mb-5">
                      Guaranteed Response within One Business Day!
                    </p>
                  </div>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaUserAlt />
                    </span>
                    <Form.Control placeholder="Name" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re" controlId="">
                    {" "}
                    <span>
                      <FaCommentAlt />
                    </span>
                    <Form.Control type="email" placeholder="Email" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaMobileAlt />
                    </span>
                    <Form.Control type="text" placeholder="Contact Number" />
                  </Form.Group>
                  <Form.Group
                    className="mb-4 pos-re"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {" "}
                    <span className="comspan">
                      <FaCommentDots />
                    </span>
                    <Form.Control
                      as="textarea"
                      className="textform"
                      placeholder="Comment"
                      rows={2}
                    />
                  </Form.Group>
                  <Col md={12} className="text-center mt-4">
                    <Button variant="primary btn-custom mt-3 btn-big">
                      Submit
                      <FaArrowRight className="ms-5" />
                    </Button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="width-full mt-5" src={aws} alt="indcom" />
            </Col>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>Our AWS Development Capabilities</h2>
              <p>
                {" "}
                In today’s world, having a solid online presence implies a
                successful business. We help you to bring your online ambitions
                to life. We follow a strategic approach to deliver defect-free
                web applications to our clients. We are a leading AWS
                development company in Bangladesh that thoroughly blueprints and
                manage strategies that optimize your digital ambitions. From AWS
                web development to integration and migration, we go all the way
                around the clock to make sure our customers are gaining online
                success.{" "}
              </p>
              <h3 className="mt-4 pt-3">What Are You Trying To Solve?</h3>
              <p>
                <strong>
                  Are You Experiencing More Downtime And Latency With Your
                  Traditional Enterprise Application?
                </strong>
              </p>

              <p>
                We got you covered, our AWS developers can architect, build, and
                deploy cloud-native enterprise applications on PaaS/IaaS
                platforms.
              </p>

              <p>
                <strong>
                  Are You Looking To Migrate Your On-Premise Web Delivery To A
                  Cloud-Based SaaS Model?
                </strong>
              </p>
              <p>
                We will help you develop enterprise-level cloud-products that
                bring high agility, reliability, and resiliency to your
                business.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h5>AWS At Swift IT</h5>
              <h2>Our Team Help You Move Your Business To AWS Cloud</h2>
              <p>
                We provide AWS cloud application development services for all
                your enterprise needs. Together with our team, your application
                is secure, scalable, flexible, and reliable. Leverage our cloud
                application development services to scale your business so that
                you can achieve your digital ambitions.{" "}
              </p>
            </Col>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="abouimg mt-5" src={about} alt="about" />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>What We Offer ?</h2>
              <p>
                {" "}
                Millions of businesses across the world trust Amazon Web
                Services to fuel their infrastructure as it is the only cloud
                solution that offers a robust suite of products and services
                that range from storage to analytics. Unlock the power of AWS to
                the fullest with our AWS Web App Development services. We
                deliver a comprehensive array of AWS services that lets you
                scale up your business and power your online ecosystem. Our
                developers are updated with the latest improvements in the AWS
                cloud and its security best practices and are constantly on the
                hunt for our vision so that we can help our clients with their
                cloud ambitions.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaLaptop />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Integration</h5>
                    <p className="mb-0">
                      {" "}
                      Use our AWS Integration services to manage the demanding
                      integration needs of your business with ease. Our AWS
                      experts help you to reduce your development and
                      maintenance costs without compromising the security and
                      stability of your site.{" "}
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Cloud Automation</h5>
                    <p className="mb-0">
                      Enjoy stable infrastructure and less downtime by
                      harnessing our cloud automation services. We will
                      configure a continuous delivery pipeline and automate
                      provisioning and deployment to enable rapid delivery.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSoundcloud />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img className="img-width" src={serviceall} alt="serviceall" />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaServer />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Application Support</h5>
                    <p className="mb-0">
                      {" "}
                      Want to get the most out of your Amazon Web Services? We
                      offer AWS Application Support Services that can do exactly
                      that. With our expert know-how and specialist experience,
                      we can optimize your cloud computing operations, with
                      uninterrupted AWS support every step of the way. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Migration</h5>
                    <p className="mb-0">
                      {" "}
                      Ready to switch to cloud storage? we’re ready to help. We
                      help you out through every stage of your cloud
                      transformation, from migrating your on-premise legacy
                      infrastructure to migrating multiple complex enterprise
                      applications. So that you can reduce your infrastructure
                      overhead.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSearchengin />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature pt-3">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-right">
              <img className="width90" src={awsservice} alt="aws-service" />
            </Col>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <h2 className="mt-2 pt-4">Our AWS Development Services </h2>

              <p>
                Swift IT is a born-in-the-cloud, full-spectrum services provider
                that has full knowledge in cloud-native technologies like
                autonomous deployments that auto-scale and auto-heal, PaaS
                architectures, containers, serverless infrastructure,
                microservices architecture, etc. We own an in-house team of over
                a hundred people consisting of Cloud practitioners, solution
                architects, SysOps administrators, DevOps engineers,
                consultants, data analytics engineers, database experts, AI/ML
                specialists, and security professionals. We provide end-to-end
                services for businesses that use Amazon Web Services to create
                fully-functional cloud solutions through serverless
                architecture. is a born-in-the-cloud, full-spectrum services
                provider that has full knowledge in cloud-native technologies
                like autonomous deployments that auto-scale and auto-heal, PaaS
                architectures, containers, serverless infrastructure,
                microservices architecture, etc. We own an in-house team of over
                a hundred people consisting of Cloud practitioners, solution
                architects, SysOps administrators, DevOps engineers,
                consultants, data analytics engineers, database experts, AI/ML
                specialists, and security professionals. We provide end-to-end
                services for businesses that use Amazon Web Services to create
                fully-functional cloud solutions through serverless
                architecture.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <Col md={12} className="mt-4">
        <hr className="hr" />
      </Col>

      <section className="all-company-feature pt-3">
        <Container>
          <Row>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <h2 className="mt-2 pt-4">
                AWS Infrastructure Setup & Management
              </h2>

              <p>
                Our team has years of experience in the implementation of
                comprehensive AWS infrastructures and application architectures,
                followed by the deployment of the business applications on AWS.
                With Amazon EC2, our engineers can create virtual servers in
                minutes with your preferred operating system along with other
                features of servers; such as storage, security, ports, etc. We
                also provide our clients with guideline AWS architectures,
                inclusive of usage estimates, optimized for their requirements,
                for free. Swift IT additionally provides services for Account
                tracking, reporting, resource usage, billing optimization,
                assets optimization, etc. Our services include -
              </p>
              <ul className="list-inline list-group ul-list-about mt-4 ullist-all">
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Monitoring user access, billing and payments, and costs.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Providing usage break-up of each AWS Asset level as well as
                    group level.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Generating monthly and quarterly reports and reviews.</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Monitoring AWS usage bill, Service API usage reports, Amazon
                    S3, and Amazon CloudFront access logs regularly.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Leveraging Auto Scaling options to set conditions for
                    scaling up or down the Amazon EC2 usage.
                  </p>
                </li>
              </ul>
            </Col>

            <Col md={6} className="mt-4 mb-4" data-aos="fade-right">
              <img className="width90" src={awspic1} alt="aws-service" />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSoundcloud />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">AWS Application Development</h5>
                    <p className="mb-0">
                      {" "}
                      Our certified AWS developers can build you a Cloud-Native
                      application from scratch on the serverless architecture of
                      AWS. We leverage AWS stack such as AWS Lambda,
                      purpose-built databases like Amazon DynamoDB, and
                      development tools, like AWS Amplify and AWS CDK to develop
                      new applications and features. Our apps are capable of
                      supporting all common database engines such as MySQL,
                      PostgreSQL, SQL Server, Aurora, RedisDB, Oracle Database,
                      SQL Server, etc. We help our clients make full use of the
                      redundancy of Amazon S3, persistence elastic block storage
                      (ELB), and scalability provided by Elastic Load Balancers.
                      We are proficient in business apps solution development
                      such as SAP, Oracle, and Microsoft in the AWS cloud. Our
                      team helps app owners get the best performance out of
                      their cloud environment, from enhancing content delivery
                      to reducing dependencies. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">AWS Managed Services</h5>
                    <p className="mb-0">
                      Our team will offer ongoing management of both your
                      application and your AWS infrastructure to reduce your
                      operational overhead. Our 24/7 enterprise monitoring
                      services are backed by a team of certified and experienced
                      cloud support engineers. Cloud Watch allows us to monitor
                      the performance of applications and AWS resources in
                      real-time to proactively identify any performance issues,
                      diagnose the root causes, and take appropriate actions. We
                      will constantly monitor by defining important metrics such
                      as network traffic, disk IOs, CPU utilization and setting
                      up alerts. We are proficient in performance optimization
                      along with the re-engineering of IT infrastructure,
                      applications, and databases, for ensuring a smooth
                      adaptation to the AWS cloud. Having years of hands-on
                      experience working on the AWS Ecosystem, our professional
                      team can take care of your Cloud with our fully managed
                      services.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSoundcloud />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 mt-4">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSoundcloud />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">AWS Storage</h5>
                    <p className="mb-0">
                      Swift IT develops fit-for-purpose, future-proof data
                      storage solutions using S3, EBS, EFS, RDS, and Glacier. We
                      have consulting, architecting and engineering expertise
                      that covers all facets of storage including primary
                      storage, file & object storage, data security, backup &
                      recovery, disaster recovery, file transfer, data
                      replication, and low-cost archiving. We can protect your
                      business by leveraging S3 and other storage services in
                      the AWS Cloud to create a disaster recovery plan in the
                      cloud. We provide custom-automated backup and archival
                      solutions using native services or third-party tools while
                      ensuring data replication with redundancy and ensures high
                      availability. We can store, protect, synchronize, and
                      collaborate on unstructured file data across all
                      locations. By leveraging our experience in data centre
                      storage combined with our AWS knowledge, your organization
                      can simplify data protection for AWS infrastructure,
                      ensure regulatory compliance SLAs, and improve data
                      visibility. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 mt-4">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">AWS Analytics</h5>
                    <p className="mb-0">
                      We have a team of seasoned technologists, business
                      advisors, data scientists, and change practitioners who
                      build Big Data Analytics to manage a multitude of data
                      from hundreds of sources in real-time. With years of
                      experience in data management, we build cloud-native data
                      stores using Amazon S3, Amazon RDS, Amazon Aurora, Amazon
                      Redshift, Amazon EMR, Amazon Kinesis, and Amazon Athena.
                      We put in place data, processes, analytics tools, and
                      visualizations to empower organizations with actionable
                      and operational business analytics. We deploy a data lake
                      to consolidate data in AWS S3 from all sources, be it
                      structured or unstructured data. Our big data solution
                      approach was designed to help companies define a Big Data
                      Analytics process, adopt the right tools, and build a
                      Cloud-based Analytics engine. We help analyse the data
                      already collected, as well as explore new ways to access,
                      collect and make sense of data in ways that were never
                      before possible.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSoundcloud />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 mt-4">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSoundcloud />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">AWS Internet Of Things</h5>
                    <p className="mb-0">
                      By using the most advanced AWS suite of products and
                      services, Swift IT designs and implements AWS-based
                      infrastructures that enable connected devices to easily
                      and securely interact with one another, gather and analyze
                      sensor data, and provide valuable insights. Using IoT SDK
                      and IoT Core, we develop IoT apps for real-time data
                      sharing with AWS cloud via MQTT, HTTP, WebSockets
                      protocols. Our team can build you an end-to-end IoT
                      solution, from sensors/devices to gateways, device clouds,
                      and public clouds. We have a proven track record of
                      dealing with embedded development from the beginning and
                      has gained various experiences in hardware as well as
                      software. Based on experience gained over many years, we
                      design and implement event-driven (serverless)
                      infrastructures for IoT solutions with multiple enterprise
                      deployments under our belt. We are ready to fully design,
                      build, deploy and manage IoT workloads as well as process
                      and analyse huge streams of raw data from devices. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 mt-4 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">AWS Consulting</h5>
                    <p className="mb-0 pb-3">
                      Our Cloud consultants will perform a full assessment of
                      your project requirements, enterprise’s existing
                      infrastructure and hosting set-up, performance, and
                      security requirements. Starting from cloud readiness
                      assessment and cloud implementation strategy development.
                      We can develop a strategy for optimizing cloud performance
                      and rethink ways to transform your business operations. We
                      can either transform your existing application by
                      rationalizing and migrating workloads to the Cloud and
                      adapting application architecture, or by creating new
                      Cloud applications, as per the predefined strategy.
                      There’s also the Hybrid strategy option where we take only
                      some parts of an existing application and integrate it
                      with data sources hosted by AWS. We can build you such a
                      hybrid architecture that extends your on-premise resources
                      to the Cloud and link several complex enterprise systems
                      for an integrated solution.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSoundcloud />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature bg-deep-gray mt-5 pt-5 bg-white">
        <Container>
          <Row>
            <Col md={6} className="mt-5 pt-5 mb-4" data-aos="fade-right">
              <h2>Tools & Technologies</h2>
              <ul className="list-inline list-group ul-list-about mt-4 ullist-all">
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>OpsWorks</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Amazon Corretto</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>Amazon CodeGuru</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>AWS Cloud Development Kit</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>AWS Cloud9</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>AWS CodeArifact</p>
                </li>

                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>AWS CodeDeploy</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>AWS Command Line Interface</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    AWS Elastic Beanstalk to deploy the services and web apps
                    created with .NET, Java, PHP, and Python.
                  </p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>
                    Amazon ElastiCache and Amazon CloudFront to enhance app
                    scalability.
                  </p>
                </li>

                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>AWS CodeBuild</p>
                </li>
                <li>
                  {" "}
                  <span>
                    <FaCheckCircle />
                  </span>
                  <p>AWS CodeCommit</p>
                </li>
              </ul>
            </Col>
            <Col md={6} className="mt-4 mb-4" data-aos="fade-left">
              <img className="abouimg mt-5" src={toolstech} alt="about" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel
              className="owl-theme slider-custom"
              {...options2}
              loop
              margin={20}
              nav
            >
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worka}
                    alt="worka"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workb}
                    alt="workb"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workc}
                    alt="workc"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workd}
                    alt="workd"
                  />{" "}
                </div>
              </div>

              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worke}
                    alt="worke"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workf}
                    alt="workf"
                  />{" "}
                </div>
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
              <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu1} alt="indu1" />{" "}
                </div>
                <h5 className="text-center mt-5">Ecommerce</h5>
                <p>
                  {" "}
                  The e-commerce solutions offered at Swift IT are on-the-go and
                  assured to bring benefits with increased ROI and overall
                  productivity. We provide custom e-commerce development
                  services and develop web apps for any kind of business across
                  various industries and enterprises ranging from B2B and B2C
                  trading networks to small, middle-sized, and large businesses.
                  These custom solutions meet the user’s requirements by
                  applying specific areas of specialization.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu2} alt="indu2" />{" "}
                </div>
                <h5 className="text-center mt-5">Healthcare</h5>
                <p>
                  {" "}
                  At Swift IT, we help you develop healthcare software
                  applications that automate processes such as patient
                  management, lab management, test result analysis, and data
                  collection. For the medical technology companies, from
                  consulting to maintenance and support we always lent our
                  supporting hands to tackle your technological dilemmas.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu3} alt="indu3" />{" "}
                </div>
                <h5 className="text-center mt-5">Logistics</h5>
                <p>
                  The experts at Swift IT help you build innovative and
                  impressive tech-enabled logistics software applications to
                  streamline the supply chain and provide flawless customer
                  support. The warehouse management software also makes sure to
                  enhance warehouse efficiency. The expert logistics and
                  transportation management software offered by Swift IT thereby
                  help you do your tasks more effectively.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu4} alt="indu4" />{" "}
                </div>
                <h5 className="text-center mt-5">Education</h5>
                <p>
                  {" "}
                  In the education field, Swift IT is engaged in integrated
                  learning system software development, virtual learning
                  management system, LMS software development, and even
                  personalized e-learning software systems. Interactive,
                  convenient, and cost-effective solutions are what we offer at
                  Swift IT.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu5} alt="indu5" />{" "}
                </div>
                <h5 className="text-center mt-5">Banking</h5>
                <p>
                  {" "}
                  From financial software development, custom banking software
                  development, digital banking software services, and custom
                  Fintech development, we at Swift IT extend our software
                  development experience in the industry of banking and finance
                  as well. With custom features, and UI/UX designs we ensure the
                  highest standards and security to the software developed.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu6} alt="indu6" />{" "}
                </div>
                <h5 className="text-center mt-5">Manufacturing</h5>
                <p>
                  {" "}
                  Implement manufacturing software for ensuring quality
                  management and maintenance software including the application
                  of intelligent products that help in accelerating the existing
                  processes and bringing down operational costs.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default Amazonweb;
