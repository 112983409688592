import React from "react";

import Common from "../../layouts/Common";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { FaLockOpen } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaWaveSquare } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaSlideshare } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaArrowRight } from "react-icons/fa";

import { FaStarOfLife } from "react-icons/fa";
import { FaScribd } from "react-icons/fa";
import { FaFileCode } from "react-icons/fa";
import { FaSearchengin } from "react-icons/fa";
import { FaSolarPanel } from "react-icons/fa";
import { FaScrewdriver } from "react-icons/fa";
import { FaSlack } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import worka from "../../assets/images/worka.png";
import workb from "../../assets/images/workb.png";
import workc from "../../assets/images/workc.png";
import workd from "../../assets/images/workd.png";
import worke from "../../assets/images/worke.png";
import workf from "../../assets/images/workf.png";
import indu1 from "../../assets/images/indu1.svg";
import indu2 from "../../assets/images/indu2.svg";
import indu3 from "../../assets/images/indu3.svg";
import indu4 from "../../assets/images/indu4.svg";
import indu5 from "../../assets/images/indu5.svg";
import indu6 from "../../assets/images/indu6.svg";
import databasemanagementservicepic from "../../assets/images/database-management-service-pic.svg";
import hire from "../../assets/images/hire.svg";
import soft from "../../assets/images/soft.svg";

import sof1 from "../../assets/images/sof1.svg";
import content from "../../assets/images/content.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const DatabaseManagementServices = () => {
  const options2 = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <Common>
      <section className="all-sub-banner">
        <div className="sub-banner">
          <Row className="m-0">
            <Col lg={8} sm={12}>
              <div className="slider-banner slide-arrow">
                <h1 className="text-white">
                  <strong>Database Management Services in Bangladesh </strong>
                </h1>
                <p className="text-white p20 mb-4">
                  Swift IT can provide consistent database management services
                  to track and organize data for the smooth running of your
                  business. By leveraging our uninterrupted data management
                  services in Bangladesh, you can securely handle huge amounts
                  of data seamlessly. We have proven expertise in SQL and NoSQL
                  databases, so we can offer efficient data processing to make
                  better business decisions. Our team of Architects, DBAs, and
                  developers are capable of managing your mission-critical data
                  and streamlining your business process in this data-driven IT
                  environment.
                </p>
              </div>
              <Row>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaLockOpen />
                    </span>
                    <label> Security & IP Protection </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaGlobe />
                    </span>
                    <label> 5 Years Experience </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaWaveSquare />
                    </span>
                    <label> Flexible Engagement Models </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileAlt />
                    </span>
                    <label> Strict NDA Terms </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaFileInvoice />
                    </span>
                    <label> No Hidden Costs </label>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="technology-list">
                    {" "}
                    <span>
                      <FaClock />
                    </span>
                    <label> On-Time Project Delivery </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="pe-0 dis-noneall">
              <div className="get-touch-form">
                <Form>
                  <div className="mb-3 me-0 sch-block">
                    <h3 className="h3-schedule mb-1">
                      {" "}
                      <strong>Lets Discuss Your Project</strong>{" "}
                    </h3>
                    <p className="mb-5">
                      Guaranteed Response within One Business Day!
                    </p>
                  </div>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaUserAlt />
                    </span>
                    <Form.Control placeholder="Name" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re" controlId="">
                    {" "}
                    <span>
                      <FaCommentAlt />
                    </span>
                    <Form.Control type="email" placeholder="Email" />
                  </Form.Group>
                  <Form.Group className="mb-4 pos-re">
                    {" "}
                    <span>
                      <FaMobileAlt />
                    </span>
                    <Form.Control type="text" placeholder="Contact Number" />
                  </Form.Group>
                  <Form.Group
                    className="mb-4 pos-re"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    {" "}
                    <span className="comspan">
                      <FaCommentDots />
                    </span>
                    <Form.Control
                      as="textarea"
                      className="textform"
                      placeholder="Comment"
                      rows={2}
                    />
                  </Form.Group>
                  <Col md={12} className="text-center mt-4">
                    <Button variant="primary btn-custom mt-3 btn-big">
                      Submit
                      <FaArrowRight className="ms-5" />
                    </Button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="all-company-feature mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="mt-4 mb-4" data-aos="fade-left">
              <h2>Our Database Management Services</h2>
              <p>
                In today’s world, it is very difficult to manage a large volume
                of data. However, with our best-suited database management
                services, we can reduce complexity in data management and ensure
                business continuity. As one of the leading database management
                companies in Bangladesh, we can provide unique solutions that
                will solve all the major challenges of your databases. We will
                offer effective data consolidation so that you can reduce manual
                work and improve ROI.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature mb-5 all-services mt-service com-miss-vis">
        <Container>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaStarOfLife />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Patch Management</h5>
                    <p className="mb-0">
                      We can provide patching services to add new features to an
                      existing application and to fix its security
                      vulnerabilities. We will execute a patch test before
                      deploying the patches to nullify the risks like breaking
                      compatibility or crashing altogether while patching. We
                      will detect the vulnerabilities efficiently and offer
                      reliable patch management solutions that fit the
                      requirements of your business.
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Security Management</h5>
                    <p className="mb-0">
                      Protection of data from malicious threats and attacks are
                      very essential. We offer security to the database, DBMS,
                      associated applications, underlying hardware, network
                      infrastructure, etc. We can strictly adhere to Database
                      security policies to provide access to databases and keep
                      your data safe from breaches. We will follow the best
                      practices and right procedures to preserve the integrity,
                      availability, and confidentiality of your data. We
                      leverage data protection tools and platforms to monitor
                      and safeguard your sensitive information from
                      vulnerabilities.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSlideshare />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="mt-4" data-aos="fade-left">
              <img
                className="img-width"
                src={databasemanagementservicepic}
                alt="databasemanagementservicepic"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaScribd />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Performance Tuning</h5>
                    <p className="mb-0 pb-3">
                      We are able to optimise the performance of the database by
                      enhancing indexing, query response, or configuration of
                      the database's environment. We have stellar experience in
                      writing well-structured queries for the smooth execution
                      of data. Since we are one of the trusted data management
                      companies in Bangladesh, We can identify I/O bottlenecks
                      and fix the specific cause of delayed performance. Our
                      database performance tuning services comprise setting the
                      recovery interval, assigning parallelism with processors,
                      tuning network protocols, etc. and allowing businesses to
                      have superior data access. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Architecture Design</h5>
                    <p className="mb-0">
                      We utilize single-tier or multi-tier architecture to
                      design the database management system. We can assist you
                      in breaking down the entire database system into distinct
                      components that can be changed, replaced, or modified
                      independently. We will choose the right architecture for
                      your critical business information and aid you in managing
                      data efficiently. We will use trendy design software to
                      design the architecture for your database systems with
                      complete security.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaFileCode />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaSearchengin />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Administration And Monitoring</h5>
                    <p className="mb-0 pb-4">
                      To accomplish sound performance for database applications,
                      we select appropriate structures and access paths for
                      database files. We can provide insights into data at an
                      organisational level and ensure that the data are
                      presented in a consistent manner. We effectively use
                      Scripts to automate database administration tasks and
                      performance monitoring of databases. We will analyse your
                      database thoroughly and take the necessary measures to
                      enhance its performance and efficiency. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-5">
                <Row>
                  <Col md={9} className="pe-0 text-end">
                    <h5 className="p-0">Backup And Recovery Management</h5>
                    <p className="mb-0">
                      We can incorporate a backup plan to create and store
                      copies of data in a separate system or medium and protect
                      businesses against data loss. We take Physical backups and
                      Logical backups efficiently and safeguard the data from
                      system crashes, transaction failure, network failure, disk
                      failure, media failure, etc. We do the backup process at
                      regular intervals to minimize the amount of data loss
                      while recovering. We implement a recovery plan to restore
                      the data in the original location where it is damaged or
                      lost and will help you keep the consistency of your data.
                    </p>
                  </Col>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-right">
                      <FaSolarPanel />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="company-slide mission-vission mission-vission2 pb-3">
                <Row>
                  <Col md={3}>
                    <div className="slider-icon icon-all" data-aos="flip-left">
                      <FaScrewdriver />
                    </div>
                  </Col>
                  <Col md={9} className="ps-0">
                    <h5 className="p-0">Database Upgradation And Migration</h5>
                    <p className="mb-0 pb-4">
                      We can provide comprehensive up-gradation and migration
                      services to modernize your database environment without
                      data loss or downtime. We can help you convert your
                      current environment into a new release environment to
                      receive the latest functionality. During migration, we
                      will move your database environment to the new OS, new
                      hardware, or a new platform like the cloud, and ensure
                      that the whole application stack performs well in a new
                      environment. .{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="clearfix"></div>

      <section className="mt-5">
        <Container className="database-block">
          <h2 className="text-center">
            What Makes Swift IT Your Trusted Partner
            <br />
            In Database Management?
          </h2>
          <p className="text-center">
            We are adept at accomplishing different database management tasks
            like designing, planning, setup & installations, restoration, and
            database upgrades. We can help you develop the most complex database
            design and ensure unrivalled quality services. We provide innovative
            solutions to handle large amounts of data on a secure base. Our
            on-demand DBA resource can help you manage, monitor, administer, and
            optimize your data according to your specific needs.
          </p>
          <Row className="soft-block">
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={hire} alt="hire" />
                </span>
                <h5 className="text-center mt-5">Dedicated Project Team</h5>
                <p>
                  We have dedicated resources who can develop databases &
                  database applications and ensure that your project is
                  completed in a timely manner. Our development team is
                  committed to ensuring secure data using relevant database
                  applications.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={content} alt="content" />
                </span>
                <h5 className="text-center mt-5">Tailor-Made Applications</h5>
                <p>
                  We can assist you in achieving your business objectives by
                  developing databases that cater to your specific business
                  needs. We will put our maximum effort to design and develop
                  data operations that are needed for balancing the
                  administration of business processes.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={soft} alt="soft" />
                </span>
                <h5 className="text-center mt-5">Advanced Toolkit</h5>
                <p>
                  To make the database applications reliable, functionally
                  robust, and secure, we leverage all of the latest and advanced
                  tools & techniques available. To ensure that your DBMS is
                  flawless and functional, our team will employ comprehensive
                  security measures and use the latest testing techniques.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={sof1} alt="sof1" />
                </span>
                <h5 className="text-center mt-5">Budget-Friendly</h5>
                <p>
                  We will do a deep analysis of your requirements in order to
                  precisely scope the project. We've created databases and
                  database applications for businesses of all types while
                  staying within their budget and industry standards.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <FaSlack />
                </span>
                <h5 className="text-center mt-5">Guaranteed Assistance</h5>
                <p>
                  Our analysts will capture your operational data and scope the
                  project to ensure that it is both cost-effective and completed
                  on time. Our support engineers will provide patching services
                  and technical assistance once your software applications have
                  been implemented.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature">
                {" "}
                <span
                  data-aos="fade-right"
                  data-aos-offset="320"
                  data-aos-easing="ease-in-sine"
                >
                  <FaSolarPanel />
                </span>
                <h5 className="text-center mt-5">Technical Proficiency</h5>
                <p>
                  We can implement the best DBMS required by your company and
                  will utilize cutting-edge technology and comprehensive
                  security measures to develop databases and database
                  applications.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-company-feature bg-deep-gray mt-5 pt-5">
        <Container>
          <Row>
            <Col md={12} className="text-start mt-4 mb-4">
              <h2>Our Recent Works</h2>
            </Col>
          </Row>
          <Row>
            <OwlCarousel
              className="owl-theme slider-custom"
              {...options2}
              loop
              margin={20}
              nav
            >
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worka}
                    alt="worka"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workb}
                    alt="workb"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workc}
                    alt="workc"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workd}
                    alt="workd"
                  />{" "}
                </div>
              </div>

              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={worke}
                    alt="worke"
                  />{" "}
                </div>
              </div>
              <div class="item">
                <div className="work-block-slide">
                  {" "}
                  <img
                    className="img-width-full"
                    src={workf}
                    alt="workf"
                  />{" "}
                </div>
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </section>
      <section className="all-company-feature">
        <Container>
          <Row>
            <Col md={12} className="text-center mt-5 pt-5">
              <h2>Our Industry Experience</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu1} alt="indu1" />{" "}
                </div>
                <h5 className="text-center mt-5">Ecommerce</h5>
                <p>
                  {" "}
                  The e-commerce solutions offered at Swift IT are on-the-go and
                  assured to bring benefits with increased ROI and overall
                  productivity. We provide custom e-commerce development
                  services and develop web apps for any kind of business across
                  various industries and enterprises ranging from B2B and B2C
                  trading networks to small, middle-sized, and large businesses.
                  These custom solutions meet the user’s requirements by
                  applying specific areas of specialization.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu2} alt="indu2" />{" "}
                </div>
                <h5 className="text-center mt-5">Healthcare</h5>
                <p>
                  {" "}
                  At Swift IT, we help you develop healthcare software
                  applications that automate processes such as patient
                  management, lab management, test result analysis, and data
                  collection. For the medical technology companies, from
                  consulting to maintenance and support we always lent our
                  supporting hands to tackle your technological dilemmas.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu3} alt="indu3" />{" "}
                </div>
                <h5 className="text-center mt-5">Logistics</h5>
                <p>
                  The experts at Swift IT help you build innovative and
                  impressive tech-enabled logistics software applications to
                  streamline the supply chain and provide flawless customer
                  support. The warehouse management software also makes sure to
                  enhance warehouse efficiency. The expert logistics and
                  transportation management software offered by Swift IT thereby
                  help you do your tasks more effectively.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu4} alt="indu4" />{" "}
                </div>
                <h5 className="text-center mt-5">Education</h5>
                <p>
                  {" "}
                  In the education field, Swift IT is engaged in integrated
                  learning system software development, virtual learning
                  management system, LMS software development, and even
                  personalized e-learning software systems. Interactive,
                  convenient, and cost-effective solutions are what we offer at
                  Swift IT.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu5} alt="indu5" />{" "}
                </div>
                <h5 className="text-center mt-5">Banking</h5>
                <p>
                  {" "}
                  From financial software development, custom banking software
                  development, digital banking software services, and custom
                  Fintech development, we at Swift IT extend our software
                  development experience in the industry of banking and finance
                  as well. With custom features, and UI/UX designs we ensure the
                  highest standards and security to the software developed.{" "}
                </p>
              </div>
            </Col>
            <Col lg={4} sm={12}>
              <div className="company-feature industry-feature">
                <div
                  className="indus-img"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  <img src={indu6} alt="indu6" />{" "}
                </div>
                <h5 className="text-center mt-5">Manufacturing</h5>
                <p>
                  {" "}
                  Implement manufacturing software for ensuring quality
                  management and maintenance software including the application
                  of intelligent products that help in accelerating the existing
                  processes and bringing down operational costs.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Common>
  );
};

export default DatabaseManagementServices;
