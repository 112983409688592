import React from "react";
import { Button } from "react-bootstrap";
import { FaMobileAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import { FaArrowRight } from "react-icons/fa";
import Col from "react-bootstrap/esm/Col";

const FreeQuote = () => {
  return (
    <div className="get-touch-form dis-pro">
      <Form>
        <div className="mb-3 me-0 sch-block">
          <h3 className="h3-schedule pt-0 mb-1">
            {" "}
            <strong>Lets Discuss Your Project</strong>{" "}
          </h3>
          <p className="mb-5">Guaranteed Response within One Business Day!</p>
        </div>
        <Form.Group className="mb-4 pos-re">
          {" "}
          <span>
            <FaUserAlt />
          </span>
          <Form.Control placeholder="Name" />
        </Form.Group>
        <Form.Group className="mb-4 pos-re" controlId="">
          {" "}
          <span>
            <FaCommentAlt />
          </span>
          <Form.Control type="email" placeholder="Email" />
        </Form.Group>
        <Form.Group className="mb-4 pos-re">
          {" "}
          <span>
            <FaMobileAlt />
          </span>
          <Form.Control type="text" placeholder="Contact Number" />
        </Form.Group>
        <Form.Group
          className="mb-4 pos-re"
          controlId="exampleForm.ControlTextarea1"
        >
          {" "}
          <span className="comspan">
            <FaCommentDots />
          </span>
          <Form.Control
            as="textarea"
            className="textform"
            placeholder="Comment"
            rows={2}
          />
        </Form.Group>
        <Col md={12} className="text-center mt-4">
          <Button variant="primary btn-custom mt-3 btn-big">
            Submit
            <FaArrowRight className="ms-5" />
          </Button>
        </Col>
      </Form>
    </div>
  );
};
export default FreeQuote;
